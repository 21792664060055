const isFileType = (type, size = 123) => {
    let prevPicMax = 5000000;
    let isSmallEn = true;
    if(size > prevPicMax || size == "") {
        isSmallEn = false;
    }
    if(type == '.pdf' || type == 'pdf') {
        return 'pdf';
    }
    if(type == '.jpg'|| type == '.png' || type == '.jpeg' || type == "image/jpeg"
    ) {
        if(isSmallEn) {
        return 'img';
        }
        else {
            return 'bigImg';
        }
    }
    if( type == '.svg') {
        return 'svg';
    }
    if(type == '.doc' || type == '.docx') {
        return 'doc';
    }
    if(type == '.tiff') {
        return 'tiff';
    }
    if(type == '.eps') {
        return 'eps';
    }
    if(type == '.zip') {
        return 'zip';
    }
    if(type == '.mov'|| type == '.mp4' || type == '.webm' || type == '.gif') {
        return 'movie';
    }
}

export default isFileType;