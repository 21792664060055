<template>
<div v-if="!loading" class="filesViewContainer">
    <div class="fileListRowHeader  w-full" v-if="header">
        <div class="col-md-6 fyCenter text-3xl">
            {{headerlabel}}   
            <div>
                <div class="bgChooseCol">
                    <div @click="tileBG = ''" class="bgDef">

                    </div>
                    <div @click="tileBG = 'bg-gray-500'" class="bgLight bg-gray-500">

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 flex flex-wrap mt-6 md:mt-0 md:justify-end w-full">
            <v-select v-model="selectMat" class="w-56 mb-4 md:mb-0 md:w-56 mr-4" :clearable="false" :searchable="false" label="Name" :options="matSelect" />
            <v-select v-model="selectDep" class="w-56 mb-4 md:mb-0 md:w-56 w-56" :searchable="false" :clearable="false" label="Name" :options="depSelect" />
            <div class="changeViewWrapper hidden md:flex">
                <div @click="view='list'" class="changeToList changeViewFiles" :class="{'active' : view == 'list'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.615" height="24" viewBox="0 0 28.615 24">
                        <path id="list-solid" d="M20.154,48a1.383,1.383,0,0,1,1.385,1.385v2.769a1.381,1.381,0,0,1-1.385,1.385H17.385A1.383,1.383,0,0,1,16,52.154V49.385A1.385,1.385,0,0,1,17.385,48Zm22.615.923a1.846,1.846,0,0,1,0,3.692H26.154a1.846,1.846,0,1,1,0-3.692Zm0,9.231a1.846,1.846,0,0,1,0,3.692H26.154a1.846,1.846,0,1,1,0-3.692Zm0,9.231a1.846,1.846,0,0,1,0,3.692H26.154a1.846,1.846,0,1,1,0-3.692ZM16,58.615a1.383,1.383,0,0,1,1.385-1.385h2.769a1.381,1.381,0,0,1,1.385,1.385v2.769a1.381,1.381,0,0,1-1.385,1.385H17.385A1.383,1.383,0,0,1,16,61.385Zm4.154,7.846a1.381,1.381,0,0,1,1.385,1.385v2.769A1.381,1.381,0,0,1,20.154,72H17.385A1.383,1.383,0,0,1,16,70.615V67.846a1.383,1.383,0,0,1,1.385-1.385Z" transform="translate(-16 -48)" fill="currentColor" />
                    </svg>
                </div>
                <div @click="view='grid'" class="changeToGrid changeViewFiles" :class="{'active' : view == 'grid'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="dashboard-icon" transform="translate(-55 -190)">
                            <g id="Group_2256" data-name="Group 2256" transform="translate(55 190)">
                                <rect id="Rectangle_1479" data-name="Rectangle 1479" width="10" height="10" rx="1" transform="translate(14)" fill="currentColor" />
                                <rect id="Rectangle_1480" data-name="Rectangle 1480" width="10" height="10" rx="1" fill="currentColor" />
                                <rect id="Rectangle_1481" data-name="Rectangle 1481" width="10" height="10" rx="1" transform="translate(0 14)" fill="currentColor" />
                                <rect id="Rectangle_1482" data-name="Rectangle 1482" width="10" height="10" rx="1" transform="translate(14 14)" fill="currentColor" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <div class="my-6">
        <div v-if="view == 'list'">
            <div class="tableHeader fileListRow row">
                <div class="fileTileCol">
                    Datei
                </div>
                <div class="fileDateCol">
                    Letztes Update
                </div>
                <div class="fileSizeCol">
                    Dateigröße
                </div>
                <div>
                    Info
                </div>
            </div>
            <div v-if="sortFiles().length">
                <div v-for="(f) in sortFiles()" :key="f.id" class="fileListRow row filesView">
                    <div class="fileTileCol">
                        <router-link :to="'/files/showFileById/'+f.id">
                            <div :class="tileBG">
                            <prevFileImg :file="f.tFile" />
                        </div>

                            <div class="imgTitle text-base md:ml-6">
                                {{f.attributes.Name}}
                            </div>
                        </router-link>
                    </div>
                    <div class="fileDateCol">
                        {{ formateDateView(f.attributes.updatedAt)}}
                    </div>
                    <div class="fileSizeCol">
                        
                        {{fSize(f.tFile.size)}}
                    </div>
                    <div class="fyCenter">
                        <span v-if="f.attributes.Comments">
                            <span class="updateBadge mr-3" v-html="f.attributes.Comments.length">
                            </span>
                        </span>
                        <span v-if="f.attributes.DescType == 'warning'">
                            <span class="updateBadge mr-3 warning">
                                !
                            </span>
                        </span>
                        <div v-if="f.attributes.collections.data.length" class="relative">
                            <span @click="showGroups = f.id" class="text-xs cursor-pointer p-1 filesViewHasGroup rounded-md bgCamp cPresent updateBadge text-center">
                                <img src="../../assets/icon/fileGroup.svg" alt="">
                            </span>
                            <div class="selectGroupOV" :class="{'active' : showGroups == f.id}">
                                <div class="fxEnd -mt-5">
                                    <div @click="showGroups = false" class="closeBtn mt-2">X</div>
                                </div>
                                <div class="groupChooseWrapper">
                                    <router-link v-for="c in f.attributes.collections.data" :key="c.id" :to="'/group/groupById/'+c.id" class="w-full mb-2 block cWhite">
                                        {{c.attributes.Name}}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full text-center my-24 text-2xl" v-else>
                Keine Dateien vorhanden!
            </div>
        </div>
        <div class="gridViewWrapper" v-if="view == 'grid'">
            <div v-if="sortFiles().length">
                <div class="gridWrapper row">
                    <div  v-for="(f) in sortFiles()" :key="f.id" class="fileTileWrapper">
                        <div class="fileTileContWrapper">
                            <div class="fileTilePrev">
                                <div v-if="isInGroup" @click="openMod('deleteFromCollection', f)" class="deleteFromGroupWrapper top-1 cursor-pointer right-1 w-6 h-6 fCenter absolute">
                                    <img src="@/assets/icon/delete.svg" alt="">
                                </div>
                                <prevFileImg class="absStretch" :class="tileBG" :file="f.tFile" />
                            </div>
                            <div class="fileTileInfo p-8">
                                <div class="fileTileTitle">
                                    <router-link :to="'/files/showFileById/'+f.id"> {{f.attributes.Name}}</router-link>
                                </div>
                                <div class="fileTileControls">
                                    <div class="fileTileShow">
                                        <router-link :to="'/files/showFileById/'+f.id">
                                            DATEI ANZEIGEN
                                        </router-link>
                                    </div>
                                    <div class="fileSizeCol">
                                        {{fSize(f.tFile.size)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full text-center my-24 text-2xl" v-else>
                Keine Dateien vorhanden!
            </div>
        </div>
    </div>
    <div :class="['fspModal contModal ', modOpen == 'deleteFromCollection' ? 'activeMod' : '']">
        <div class="modal-cont-sm  text-center">
            <div class="fxEnd -mt-5">
                <div @click="openMod(false)" class="closeBtn">X</div>
            </div>
            <div class="modalAddHeading mt-3">Wirklich entfernen?</div>
            <div class="allGroupsWrapper fCenter py-2">
                <div @click="deleteFromCollection()" class="btnOutline small mx-3 mb-6">
                    Entfernen
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import { getDepartmens, getMaterials } from '@/graphql/queries'
import formatDate from '@/composables/formats';
import fileSizing from '@/composables/formatSize';
import isFileType from '@/composables/formatType';
import getTheFile from '@/composables/getTheFile';
import { updateFileCollection } from '@/graphql/mutations'
import prevFileImg from '@/components/filesystem/prevFileImg.vue';

export default {
    components: {
        prevFileImg
    },
    props: {
        header: {
            type: Boolean,
            default: true
        },
        headerlabel: {
            type: String,
            default: 'Dateien'
        },
        view: {
            type: String,
            default: "list"
        },
        files: {
            type: Array,
            default: []
        },
        isInGroup: {
            type: String,
            default: false
        }

    },
    data() {
        return {
            fileGroupDelete: null,
            modOpen: false,
            loading: true,
            uDepartment: {
                id: 0,
                Name: ''
            },
            dSort: -1,
            showGroups: false,
            selectDep: {},
            depSelect: [],
            dMaterial: -1,
            selectMat: {},
            matSelect: [],
            tileBG: ''
        }
    },
    apollo: {
        departments: {
            query: getDepartmens,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result() {
                this.depSelect[0] = {
                    "id": -1,
                    "Name": "Alle Departments"
                };
                this.departments.data.forEach(d => {
                    if (parseInt(d.id) == this.$store.state.user.user.Department) {
                        this.uDepartment.id = d.id;
                        this.uDepartment.Name = d.attributes.Name
                        this.selectDep = {
                            "id": d.id,
                            "Name": d.attributes.Name
                        }
                    } else {
                        this.selectDep = {
                            "id": -1,
                            "Name": "Alle Departments"
                        }
                    }
                    this.depSelect.push({
                        "id": d.id,
                        "Name": d.attributes.Name
                    })
                    this.dSort = this.$store.state.user.user.Department;
                });
            }
        },
        materials: {
            query: getMaterials,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result() {
                this.matSelect[0] = {
                    "id": -1,
                    "Name": "Alle Materialien"
                };
                this.selectMat = this.matSelect[0];
                this.materials.data.forEach(d => {
                    this.matSelect.push({
                        "id": d.id,
                        "Name": d.attributes.Name
                    })

                });
                this.loading = false;
            }
        },

    },
    methods: {
        
        depFilter(id) {
            this.dSort = id;
            this.sortFiles;
        },
        fSize(size) {
            return fileSizing(size);
        },
        formateDateView(date) {
            return formatDate(date, 'de');
        },
        isFile(type) {
            return isFileType(type);
        },
        sortFiles() {
            var nsfiles = [...this.files];
            // console.log(this.files);
            var sfiles = [];
                nsfiles.map(e => {
                    let f = {...e};
                    let depIn = false;
                    if(this.dSort != -1) {
                    f.attributes.departments.data.forEach(d => {
                        if (parseInt(d.id) == this.dSort) {
                        depIn = true;
                        }
                    });
                    } else {
                        depIn = true;
                    }
                    let matIn = false;
                    if(this.dMaterial != -1) {
                    f.attributes.materials.data.forEach(d => {
                        if (parseInt(d.id) == this.dMaterial) {
                            matIn = true;
                        }
                    });
                    } else {
                        matIn = true;
                    }
                    let tFile = getTheFile({"data":f});
                    f.tFile = tFile;
                    if(matIn === true && depIn === true) {
                        sfiles.push(f);
                    }
                });

            sfiles.sort(function (a, b) {
                return new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt);
            });

            return sfiles;
        },
        openMod(state, file = false) {
            this.modOpen = state;
            this.fileGroupDelete = file;
            this.$store.dispatch('openMod', state);
        },
        deleteFromCollection() {
            var index = this.fileGroupDelete.attributes.collections.data.map(object => object.id).indexOf(this.isInGroup);
            var nArr = [...this.fileGroupDelete.attributes.collections.data];
            nArr.splice(index, 1);
            this.$apollo.mutate({
                mutation: updateFileCollection,
                context: {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                },
                variables: {
                    "id": this.fileGroupDelete.id,
                    "data": {
                        "collections": nArr
                    }

                }
            }).then((res) => {
                this.$emit('updateCollections', true);
                this.openMod(false);
            });
        }
    },
    watch: {
        selectDep(newCont) {
            this.depFilter(newCont.id);
        },
        selectMat(newCont) {
            this.dMaterial = newCont.id;
            this.sortFiles;
        }
    },
    mounted() {
        // console.log(this.files);
    }
}
</script>

<style>
.departmentFilter .btnChoose {
    margin-bottom: 0
}
</style>
