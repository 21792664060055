<template>
<div class="containerWrapper">
    <vue-multiselect v-model="sCont" :taggable="false" track-by="id"  @select="toggleActive" :multiple="false" label="label" placeholder="Wähle den Speicherort." :options="avConts"></vue-multiselect>
</div>
</template>

    
    
<script>
import { getContainer } from '@/graphql/queries'

import VueMultiselect from 'vue-multiselect'
export default {
    components: {
        VueMultiselect
    },
    data() {
        return {
            avConts: [],
            sCont: {}
        }
    },
    apollo: {
        $loadingKey: 'loading',
        containers: {
            query: getContainer,
            prefetch: true,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            result(res) {
                res.data.containers.data.forEach(c => {
                    let name = c.attributes.Name;
                    c.attributes.sub_containers.data.forEach(s => {
                        let cObject = {
                            'parentid': c.id,
                            'label': name + ' - ' + s.attributes.Name,
                            'id': s.id
                        }
                        this.avConts.push(cObject);
                    });

                });
                this.avConts.sort((a, b) => a.label.localeCompare(b.label))
                this.sCont = this.avConts[0];
            }
        },
    },
    methods: {
        toggleActive(id) {
        
            this.$emit('updateSubCont',id);
        },
        // sCollUpdates(value) {
        //     this.$emit('sConts', value);
        // },
    }
}
</script>
