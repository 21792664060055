<template>
<div class="collectionWrapper">
    <!-- <v-select @change="sCollUpdates" class="bgPresent" v-model="sColls" multiple taggable :create-option="createCollection(collection)" :options="avColls" placeholder="Gruppe auswählen oder Erstellen"></v-select> -->
    <vue-multiselect v-model="sColls" :taggable="true" @tag="createCollection" track-by="id" :multiple="true" label="label" placeholder="Gruppe auswählen oder hinzufügen." :options="avColls"></vue-multiselect>
</div>
</template>

<script>
import { getCollections } from '@/graphql/queries'
import { createCollection } from '@/graphql/create'
import formatDate from '@/composables/formats'

import VueMultiselect from 'vue-multiselect'
export default {
    components: {
        VueMultiselect
    },
    props: {
        sColls: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            avColls: [],
        }
    },
    watch: {
        sColls: function (newVal) {
            this.sCollUpdates(newVal);
        }
    },
    apollo: {
        $loadingKey: 'loading',
        collections: {
            query: getCollections,
            prefetch: true,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            result(res) {
                res.data.collections.data.forEach(c => {
                    let cObject = {
                        'label': c.attributes.Name,
                        'id': c.id
                    }
                    this.avColls.push(cObject);
                });
            }
        },
    },
    methods: {
        sCollUpdates(value) {
            this.$emit('sColls', value);
        },
        createCollection(collection) {
            this.$apollo.mutate({
                    mutation: createCollection,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: {
                        "data": {
                            "Name": collection,
                            "publishedAt": formatDate('', 'iso')
                        }

                    },

                })
                .then((res) => {
                    let c = res.data.createCollection.data;
                    let cObject = {
                        'label': c.attributes.Name,
                        'id': c.id
                    }
                    this.avColls.push(cObject);
                    this.sColls.push(cObject)
                });
        }
    }
}
</script>
