<template>
<div v-if="file" class="fileTemplate">
    <div class="row">
        <div class="w-full fyCenter mb-6">
            <div class="secInd">
                DATEI
            </div>
            <div class="bgChooseCol mb-4">
                    <div @click="tileBG = ''" class="bgDef">

                    </div>
                    <div @click="tileBG = 'bg-gray-500'" class="bgLight bg-gray-500">

                    </div>
                </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="previewContainer" :class="tileBG">
                <div v-if="hasState" class="fileStateWrapper">
                    <div class="fileState secInd" :style="getStateColor()">
                        <span class="mr-4" v-html="getStateLabel()"></span>
                        <div v-if="loggedInUser.canUpload === true" @click="openMod('changeStateModal')" class="changeState fCenter w-8 h-8  border-l bDark">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 cursor-pointer w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div v-if="sColls.length" class="fileGroupWrapper">
                    <div @click="showGroups = !showGroups" class="secInd cursor-pointer w-10 h-10 fCenter">
                        <img src="../../assets/icon/fileGroup.svg" alt="">
                    </div>
                    <div class="selectGroupOV" :class="{'active' : showGroups}">
                        <div class="fxEnd -mt-5">
                            <div @click="showGroups = false" class="closeBtn mt-2">X</div>
                        </div>
                        <div class="groupChooseWrapper">
                            <router-link v-for="c in sColls" :key="c.id" :to="'/group/groupById/'+c.id" class="w-full mb-2 block cWhite">
                                {{c.attributes.Name}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <prevFileImg :file="tFile" />
            </div>
        </div>
        <div class="col-md-6 fileInfoContainer">
            <div class="w-full">
                <div class="flex justify-between">
                    <div class="w-full">
                        <div class="label">
                            DATEITITEL
                        </div>
                        <div class="fileInfoTitle">
                            {{file.data.attributes.Name}}
                        </div>
                    </div>
                    <div v-if="$store.state.user.user.canUpload">
                        <img @click="$router.push({ path: '/files/editfilebyid/' + file.data.id });" class="h-6 cursor-pointer mt-6 w-auto" src="../../assets/icon/edit.svg" alt="">
                    </div>
                </div>
                <div class="label">
                    Beschreibung
                </div>
                <div v-if="file.data.attributes.Description">
                    <div class="fileAttrDesc w-full" :class="{'warning' : file.data.attributes.DescType == 'warning'}">
                        {{file.data.attributes.Description}}
                    </div>
                </div>
                <div class="" v-else>
                    -
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mdpx0">
                    <div class="attrCont">
                        <div class="label">
                            Ablageort
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.containers.data.length">
                                <div v-for="d in file.data.attributes.containers.data" :key="d.id" class="fileAttr">
                                    {{d.attributes.Name}}
                                </div>
                            </div>
                            <div v-if="file.data.attributes.sub_containers.data.length">
                                <div v-for="d in file.data.attributes.sub_containers.data" :key="d.id" class="fileAttr">
                                    {{d.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <div class="attrCont">
                        <div class="label">
                            Departments
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.departments.data.length">
                                <div v-for="d in file.data.attributes.departments.data" :key="d.id" class="fileAttr">
                                    {{d.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <div class="attrCont">
                        <div class="label">
                            Medium
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.mediums.data.length">
                                <div v-for="me in file.data.attributes.mediums.data" :key="me.id" class="fileAttr">
                                    {{me.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>

                    <div class="attrCont mt-4">
                        <div class="label">
                            Deadline
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.Deadline" class="">
                                {{formatDeadline}} Uhr
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mdpx0">
                    <div class="attrCont">
                        <div class="label">
                            Kanäle
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.channels.data.length">
                                <div v-for="chans in file.data.attributes.channels.data" :key="chans.id" class="fileAttr">
                                    {{chans.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <div class="attrCont">
                        <div class="label">
                            Material
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.materials.data.length">
                                <div v-for="ma in file.data.attributes.materials.data" :key="ma.id" class="fileAttr">
                                    {{ma.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <div class="attrCont">
                        <div class="label">
                            Tags
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.tags.data.length">
                                <div v-for="ta in file.data.attributes.tags.data" :key="ta.id" class="fileAttr">
                                    {{ta.attributes.Name}}
                                </div>
                            </div>
                            <div class="" v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <div class="attrCont mt-4">
                        <div class="label">
                            Größe
                        </div>
                        <div class="fileArrWrapper">
                            <div class="">
                                {{fSize(tFile.size)}}
                            </div>
                        </div>
                    </div>
                    <div class="attrCont mt-4">
                        <!-- <div class="label">
                            Dimension
                        </div>
                        <div class="fileArrWrapper">
                            <div v-if="file.data.attributes.fspFile.data.attributes.width" class="">
                                {{file.data.attributes.fspFile.data.attributes.width}} x {{file.data.attributes.fspFile.data.attributes.height}}
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div v-if="!canComment" class="actCont mt-8">
                <div class="label">Aktionen</div>
                <div class="btnGroup">
                    <div @click="copyLink" class="btnOutline smaller mr-6 mb-6">
                        Link kopieren

                        <div class="msgInfo" :class="{'active' : linkCopied}">Link kopiert</div>
                    </div>
                    <div class="btnOutline smaller mr-6 mb-6">
                        <a :href="mailText() ">
                            Mail
                        </a>
                    </div>
                    <a :href="tFile.url" target="_blank" class="btnOutline smaller mb-6" download>
                        Download
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div v-if="canComment" class="row mt-8">
        <div class="col-md-6">
            <div class="w-full">
                <div class="text-2xl">
                    Updates
                </div>
            </div>
            <div class="updateBlock my-8">
                <comments :id="file.data.id" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6 mdpx0">
                    <div class="actCont">
                        <div class="w-full">
                            <div class="text-2xl">
                                Aktionen
                            </div>
                        </div>
                        <div class="block my-8">
                            <div>
                                <div @click="copyLink" class="btnOutline smaller mr-6 mb-6">
                                    Link kopieren

                                    <div class="msgInfo" :class="{'active' : linkCopied}">Link kopiert</div>
                                </div>
                            </div>
                            <div>
                                <div class="btnOutline smaller mr-6 mb-6">
                                    <a :href="mailText() ">
                                        Mail
                                    </a>
                                </div>
                            </div>
                            <div>
                                <a :href="tFile.url" target="_blank" class="btnOutline smaller mb-6" download>
                                    Download
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mdpx0">
                    <div v-if="$store.state.user.user.canUpload" class="actCont">
                        <div class="w-full">
                            <div class="text-2xl">
                                Admin-Aktionen
                            </div>
                        </div>
                        <div class="block my-8">
                            <div>
                                <div @click="$router.push({ path: '/files/editfilebyid/' + file.data.id });" class="btnOutline smaller mr-6 mb-6">
                                    Bearbeiten
                                </div>
                            </div>
                            <div>
                                <div @click="openMod('addToGroup')" class="btnOutline smaller mr-6 mb-6">
                                    Gruppieren
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="['fspModal contModal', modOpen == 'changeStateModal' ? 'activeMod' : '']">
            <div class="modal-cont-sm text-center">
                <div class="fxEnd -mt-5">
                    <div @click="openMod(false)" class="closeBtn cPresent">X</div>
                </div>
                <div class="modalAddHeading">Status ändern</div>
                <div class="allStates">
                    <div v-for="s,index in allStates" @click="ChangeState(s.val)" class="cDark stateChangeBtn w-full mb-4 cursor-pointer" :class="{'d-none' : index == 0}" :key="s.val">
                        {{s.label}}
                    </div>
                </div>
            </div>
        </div>
        <div :class="['fspModal contModal ', modOpen == 'addToGroup' ? 'activeMod' : '']">
            <div class="modal-cont-sm w-full mw-md text-center">
                <div class="fxEnd -mt-5">
                    <div @click="openMod(false)" class="closeBtn">X</div>
                </div>
                <div class="modalAddHeading">Einer Gruppe hinzufügen</div>
                <div class="allGroups">
                    <EditCollections @sColls="transferColls" />
                </div>
                <div class="allGroupsWrapper fyCenter py-6">
                    <div @click="safeCollection(false)" class="btnOutline small mr-3 mb-6">
                        Speichern
                    </div>
                    <div @click="safeCollection(true)" class="btnOutline small mr-3 mb-6">
                        Speichern und zur Gruppe
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import formatDate from '@/composables/formats';
import fileSizing from '@/composables/formatSize';
import comments from './comments'
import fileStates from '@/composables/fileStates';
import prevFileImg from '@/components/filesystem/prevFileImg.vue';
import { updateFileState, updateFileCollections } from '@/graphql/mutations'
import EditCollections from '@/components/filesystem/editCollections.vue';

export default {
    props: {
        file: {
            type: Object,
            default: null
        },
        canComment: {
            type: Boolean,
            default: false
        },
        hasState: {
            type: Boolean,
            default: true
        }
    },
    components: { comments, EditCollections, prevFileImg },
    data() {
        return {
            linkCopied: false,
            modOpen: false,
            activeStateVal: this.file.data.attributes.FileState,
            sCollection: [],
            isEdit: false,
            showGroups: false,
            tileBG: ''
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        },
        formatDeadline() {
            return formatDate(this.file.data.attributes.Deadline, 'de_time');
        },
        allStates() {
            return fileStates;
        },
        iColls() {
            if (this.file.data.attributes.collections === undefined || this.file.data.attributes.collections.data === null) {
                let emptArr = [];
                return emptArr;
            } else {
                return this.file.data.attributes.collections.data;
            }
        },
        sColls() {
            let sCol = [...this.iColls];
            return sCol;
        },
        tFile() {
            let tFileTemp = {
                "url": "",
                "name": "",
                "size": 0,
                "ext": "",
                "version": "v1",
                "prevUrl": ""
            }
            if(this.file.data.attributes.PrevLink != '') {
                tFileTemp.prevUrl = this.file.data.attributes.PrevLink;
            }
            if (this.file.data.attributes.fspFileLink != '' && this.file.data.attributes.fspFileLink != null) {
                tFileTemp.url = this.file.data.attributes.fspFileLink;
                tFileTemp.version = "v2";
                tFileTemp.ext = this.file.data.attributes.fspFileLink.split(/[#?]/)[0].split('.').pop().trim();
                tFileTemp.size = "Unbekannt";
            } else if (this.file.data.attributes.fspFileV2 != '' && this.file.data.attributes.fspFileV2 != null) {
                tFileTemp.url = this.file.data.attributes.fspFileV2.url;
                tFileTemp.name = this.file.data.attributes.fspFileV2.name;
                tFileTemp.size = this.file.data.attributes.fspFileV2.size;
                tFileTemp.version = "v2";
                tFileTemp.ext = this.file.data.attributes.fspFileV2.name.substring(this.file.data.attributes.fspFileV2.name.lastIndexOf("."));
            } else {
                tFileTemp.url = this.file.data.attributes.fspFile.data.attributes.url;
                tFileTemp.name = this.file.data.attributes.fspFile.data.attributes.name;
                tFileTemp.size = this.file.data.attributes.fspFile.data.attributes.size;
                tFileTemp.ext = this.file.data.attributes.fspFile.data.attributes.ext;
                tFileTemp.version = "v2";
            }

            return tFileTemp;

        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.tFile.url);
            this.linkCopied = true;
            setTimeout(() => {
                this.linkCopied = false;
            }, 2000);
        },
        getThumbail(url) {
            return url.replace('amazonaws.com/', 'amazonaws.com/medium_')
        },
        mailText() {
            return 'mailto:?=Link zur Datei:' + this.file.data.attributes.Name + '&body=' + this.tFile.url;
        },
        fSize(size) {
            if (size != "Unbekannt") {
                return fileSizing(size);
            } else {
                return size;
            }
        },

        getStateLabel() {
            if (this.activeStateVal === null) {
                return fileStates[1].label;
            } else {
                let label = fileStates.find(x => x.val === this.activeStateVal).label;
                return label;
            }
        },
        getStateColor() {
            if (this.activeStateVal === null) {
                return 'background-color:' + fileStates[1].color;
            } else {
                let color = fileStates.find(x => x.val === this.activeStateVal).color;
                return 'background-color:' + color;
            }
        },
        openMod(state) {
            this.modOpen = state;
            this.$store.dispatch('openMod', state);
        },
        ChangeState(val) {

            this.$apollo.mutate({
                mutation: updateFileState,
                context: {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                },
                variables: {
                    "id": this.file.data.id,
                    "data": {
                        "FileState": val
                    }

                }
            }).then((res) => {
                this.activeStateVal = val;
                this.openMod(false);
            });
        },
        transferColls(c) {
            this.sCollection = c;
        },
        safeCollection(goToCol) {
            let tColls = [];
            this.sCollection.forEach(c => {
                if (c.id === null) {
                    console.log('NEW');
                } else {
                    tColls.push(c.id);
                }
            });

            this.$apollo.mutate({
                mutation: updateFileCollections,
                context: {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                },
                variables: {
                    "id": this.file.data.id,
                    "data": {
                        "collections": tColls
                    }

                }
            }).then((res) => {
                if (goToCol === false) {
                    this.openMod(false)
                } else {
                    this.openMod(false)
                    this.$router.push({ path: '/group/groupById/' + tColls[0] });
                }

            });
        },
        // getDlLink() {
        //     if (this.file.data.attributes.fspFileLink != '' && this.file.data.attributes.fspFileLink != null) {
        //         return this.file.data.attributes.fspFileLink;
        //     } else if (this.file.data.attributes.fspFileV2 != '' && this.file.data.attributes.fspFileV2 != null) {
        //         return this.file.data.attributes.fspFileV2.url;
        //     } else {
        //         return this.file.data.attributes.fspFile.data.attributes.url;

        //     }
        // }

    },
    mounted() {

    }
}
</script>
