import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.css'
import './assets/css/vue-select.css';
import 'v-calendar/dist/style.css';
import axios from 'axios'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client/core'
import {
  createApp,
  provide,
  h
} from 'vue'
import {
  DefaultApolloClient
} from '@vue/apollo-composable'
import {
  createApolloProvider
} from '@vue/apollo-option'
import {
  SetupCalendar,
  Calendar,
  DatePicker
} from 'v-calendar';
import vSelect from 'vue-select'
import { setContext } from '@apollo/client/link/context';



const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "https://fspback.brotsalz.de/graphql",
});

const authLink = setContext((_, {
  headers
}) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})





const fsp = createApp({
  router,
  store,
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
  mounted() {
    document.title = "FSP Campaign Tool";
  },
  created() {
    const userString = sessionStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response)
        if (error.response.status === 401) {
          this.$router.push('/')
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  }
}).use(store).use(router).use(apolloProvider).use(SetupCalendar, {}).component('Calendar', Calendar).component('v-select', vSelect).component('DatePicker', DatePicker).mount('#app');