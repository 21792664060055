const fileStates = [
    {
        "val" : null,
        "label" : "ZUR VERWENDUNG FREIGEGEBEN",
        "color": "rgb(74 222 128)"
    },
    {
        "val" : "approved",
        "label" : "ZUR VERWENDUNG FREIGEGEBEN",
        "color": "rgb(74 222 128)"
    },
    {
        "val" : "expired",
        "label" : "NICHT MEHR VERWENDEN",
        "color": "rgb(244 63 94)"
    },
]

export default fileStates;