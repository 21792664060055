<template>
<div v-if="!loading" class="ContainerView">
    <div class="w-full">
        <div class="secInd">
          Mediathek
        </div>
        <h1 class="greetingsCont text-6xl">
            {{container.data.attributes.Name}}
        </h1>
    </div>
    <div class="w-full my-4">
        <files-view :view="prevFileView()" :files="container.data.attributes.fspfiles.data" />
    </div>
</div>
</template>

<script>
import { getFilesByContainer } from '@/graphql/queries'
import filesView from '../components/filesystem/filesView.vue'
export default {
    components: { filesView },
    props: {
        fileId: {
            type: String,
        }
    },
    data() {
        return {
            loading: 0,

        }
    },
    apollo: {
        $loadingKey: 'loading',
        container: {
            query: getFilesByContainer,
            variables() {
                return {
                    "id": this.fileId
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {}
        },
    },
    methods: {
        prevFileView() {
            return this.$store.state.user.user.prevFileView;
        }
    }

}
</script>
