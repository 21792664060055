<template>
<div v-if="!loading" class="containerList">
    <div class="uploadChooseWrap">
        <div @click="toggleActive(d)" v-for="d in departments.data" :key="d.id" :class="{'active' : isActive(d.id)}" class="btnChoose">
            {{d.attributes.Name}}
        </div>
    </div>
</div>
</template>

<script>
import {
    getDepartmentsUser,
} from '@/graphql/queries';
export default {
    apollo: {
        departments: {
            query: getDepartmentsUser,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result() {
                this.loading = false;
            }
        },
    },
    data() {
        return {
            cdepartments: [],
            loading: true
        }
    },
    methods: {
        toggleActive(d) {
            this.$store.dispatch('updateToggleDeps', d);
        },
        isActive(id) {
            let res = false;
            this.$store.state.cDepartments.forEach(d => {
                if(d.id == id) {
                    res = true;
                }
            });
            return res;
        }
    }
}
</script>
