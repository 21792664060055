<template>
<div class="profileWrapper w-full">
    <div class="row mb-8 mw-md">
        <div class="col-md-6">
            <div class="secInd">
                Profil
            </div>
            <h1 class="mt-6 mb-8 greetingsCont">
                Profil bearbeiten
            </h1>
            <div class="fyEnd">
                <get-avatar :form="'big'" :type="'rounded'" :url="userInput.Avatarurl"></get-avatar>
                <div @click="openMod('uploadAvatar')" class="uploadNewAvatar">+</div>
            </div>

        </div>
        <div class="col-md-6"></div>
    </div>
    <div class="row mb-8 mw-md">
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Vorname</label>
                <input v-model="userInput.Firstname" type="text">
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Nachname</label>
                <input v-model="userInput.Lastname" type="text">
            </div>
        </div>

        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Email Adresse</label>
                <div class="noChange" v-html="loggedInUser.email"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Telefonnummer</label>
                <input v-model="userInput.Tel" type="text">
            </div>
        </div>

        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Stellenbezeichnung</label>
                <input v-model="userInput.Position" type="text">
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Rolle</label>
                <div class="noChange" v-html="loggedInUser.Rolename"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Neues Passwort</label>
                <input v-model="newPW" palceholder="•••••••" type="password">
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Neues Passwort wiederholen</label>
                <input v-model="newPWAg" palceholder="•••••••" type="password">
            </div>
        </div>
        <div class="col mt-4">
            <div :class="{'disabled nothingChanged' : !canSave }" @click="safeUserInput" class="btnPrime">
                Speichern
            </div>
        </div>

    </div>
    <div :class="['fspModal contModal', modOpen == 'uploadAvatar' ? 'activeMod' : '']">
        <div class="modal-cont-lg text-center">
            <div class="fxEnd -mt-5">
                <div @click="openMod(false)" class="closeBtn text-2xl ">X</div>
            </div>
            <div class="modal-header">
                <h2 class="succesHeaderModal text-center mx-auto mb-5">Neues Bild auswählen</h2>
            </div>
            <div class="modal-body">
                <div class="col-12 text-center py-8">
                    <div class="p-16 w-full border-2 fCenter relative border-dashed bCamp 2xl:p-32 bgPresDark">
                        <input class="uploadFileZone" type="file" v-on:change="showChange">
                    </div>
                    <div @click="submitFile" class="uploadFileBtn btnPrime">
                        DATEIEN HOCHLADEN
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="['fspModal contModal', modOpen == 'safeSuccess' ? 'activeMod' : '']">
        <div class="modal-cont-lg text-center">
            <div class="fxEnd -mt-5">
                <div @click="openMod(false)" class="closeBtn text-2xl ">X</div>
            </div>
            <div class="modal-header">
            </div>
            <div class="modal-body">
                <div class="col-12 text-center py-8">
                  <h2>Erfolgreich gespeichert!</h2>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';

import userCard from '../components/user/userCard.vue';
import {
    updateUser,
    updateAvatar
} from "../graphql/mutations";
import {
    isEqual
} from 'lodash';
import GetAvatar from '../components/user/getAvatar.vue';

export default {
    components: {
        userCard,
        GetAvatar
    },
    data() {
        return {
            userInput: {},
            initInput: {},
            canSave: false,
            newPW: '',
            newPWAg: '',
            modOpen: false,
            fileRecordsForUpload: []
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        }
    },
    mounted() {
        this.userInput = {
            "Position": this.loggedInUser.Position,
            "Firstname": this.loggedInUser.Firstname,
            "Lastname": this.loggedInUser.Lastname,
            "Tel": this.loggedInUser.Tel,
            "Avatarurl": this.loggedInUser.Avatarurl,
        };
        this.initInput = {
            ...this.userInput
        };
    },
    watch: {
        userInput: {
            handler(newVal, oldVal) {
                if (!isEqual(this.initInput, this.userInput) || this.newPWAg != '') {
                    this.canSave = true;
                } else {
                    this.canSave = false;
                }
            },
            deep: true
        },
        newPW: {
            handler(newVal, oldVal) {
                if (this.newPW != '') {
                    this.canSave = true;
                } else {
                    this.canSave = false;
                }
            }
        }
    },
    methods: {
        safeUserInput() {
            if (this.newPW != '') {
                if (this.newPW == this.newPWAg) {
                    this.userInput.password = this.newPW;
                } else {
                    alert('Die Passwörter stimmen nicht überein!');
                    return;
                }
            }
            let userInput = {
                "id": this.loggedInUser.id,
                "data": this.userInput
            };
            console.log(userInput);

            this.$apollo.mutate({
                mutation: updateUser,
                variables: userInput
            }).then((res) => {
                this.initInput = {
                    ...this.userInput
                };
                this.$store.dispatch('updateUser', this.userInput);
                this.newPW = '';
                this.newPWAg = '';
                this.openMod('safeSuccess');
            })

        },
        openMod(state) {
            this.modOpen = state;
            this.$store.dispatch('openMod', state);
        },
        showChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            console.log(files[0]);
            this.fileRecordsForUpload[0] = files[0];
        },
        submitFile() {
            this.openMod('uploadProgress');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer ' + this.$store.state.user.jwt
                },
            }
            let formData = new FormData();
            formData.append('files', this.fileRecordsForUpload[0]);
            axios.post( "https://fspback.brotsalz.de/api/upload",
                    formData, config
                )
                .then(res => {
                    var prevUrl = '';

                    if (res.data[0].mime == "image/png" || res.data[0].mime == "image/jpg" || res.data[0].mime == "image/jpeg") {
                        prevUrl = res.data[0].url.replace('.amazonaws.com/', '.amazonaws.com/small_');
                    }
                    this.$apollo.mutate({
                        mutation: updateAvatar,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "id": this.loggedInUser.id,
                            "data": {
                                Avatar: res.data[0].id,
                                Avatarurl: prevUrl
                            }

                        }
                    }).then((res) => {
                        this.$store.dispatch('updateAvatar', res.data.updateUsersPermissionsUser.data.attributes.Avatarurl);
                        this.openMod(false);
                    });

                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
}
</script>
