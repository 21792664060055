<template>
<div class="loginWrapper min-h-screen text-white bgBlack fCenter bg-cover	bg-no-repeat bg-center" :style="{ backgroundImage: `url(${bg})`}">
    <div class="contentWrap">
        <div class="loginLogo text-center my-20">
            <img class="mx-auto" src="@/assets/imgs/fspLogo.svg" alt="">
        </div>
        <div class="loginCompWrapper">
            <img src="@/assets/imgs/loginHead.svg" class="loginHead" alt="">
            <h1 class="loginHeader text-center my-8">Login Bereich</h1>
            <div class="mb-4 text-center w-full" v-if="error">Benutzer oder Passwort ist nicht korrekt.</div>
            <form @submit.prevent="userLogin">
                <div v-if="err" class="
              p-4
              mb-4
              text-sm text-red-700
              bg-red-100
              rounded-lg
              dark:bg-red-200 dark:text-red-800
            " role="alert">
                    {{ err }}
                </div>
                <div class="mb-6">
                    <input v-model="email" type="email" name="email" class="logInputTxt" placeholder="Email-Adresse" required />
                </div>
                <div class="mb-6">
                    <input v-model="password" type="password" name="password" placeholder="Passwort" class="logInputTxt" required />
                    <p class="my-4 text-center">
                        <a href="mailto:support@brotsalz.de?subject=Passwort vergessen" class="text-center cCamp" to="/user/forgot">Passwort vergessen?</a>
                    </p>
                </div>
                <button type="submit" class="btnPrime absCenterX">
                    ANMELDEN
                </button>
            </form>
        </div>
    </div>
</div>
</template>

<style>

</style>

<script>
// import {
//     mapGetters
// } from "vuex";
import logBg from "@/assets/imgs/loginBG.webp";

export default {
    auth: 'guest',
    layout: 'noauth',
    data() {
        return {
            err: null,
            email: '',
            password: '',
            bg: logBg,
            error: false
        }
    },
    methods: {
        userLogin() {
            this.$store
                .dispatch('login', {
                    identifier: this.email,
                    password: this.password
                })
                .then((data) => {
                    // sessionStorage.setItem('jwt', data.jwt);
                    this.$router.push({
                        name: 'Dashboard'
                    })
                })
                .catch(err => {
                    this.error = true;
                    this.password = '';
                })
        }
    }
}
</script>
