import axios from 'axios';

// Single file upload to S3 using a presigned URL
export const singleUpload = async (file, folder) => {
  let formData = new FormData();
  formData.append('filename', file.name);
  formData.append('filetype', file.type);
  formData.append('folder', folder || 'upload');

  try {
    console.log("PreData: ", formData);  
    const response = await axios.post('https://fspassets.brotsalz.de/uploadcomp/presigned.php', formData);
  
    const { url, key } = response.data;
    if (!url || !key) {
      throw new Error('Failed to get presigned URL');
    }

    // Create a separate Axios instance for the S3 upload, excluding the Authorization header
    const s3UploadAxios = axios.create({
      headers: {
        'Content-Type': file.type, // Only set Content-Type, no Authorization header
      },
      withCredentials: false,
      baseURL: '', // Ensure no baseURL is set
    });
    delete s3UploadAxios.defaults.headers.common['Authorization'];

    // Upload the file directly to S3 using the presigned URL
    const uploadResponse = await s3UploadAxios.put(url, file);

    console.log("Upload Response: ", uploadResponse);
    // Handle success or failure
    if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
      const bucketUrl = decodeURIComponent(url).split(key)[0];
      return {
        key: key,
        fullPath: bucketUrl + key,
        status: 200
      };
    } else {
      console.error("Upload failed:", uploadResponse);
    }
  } catch (err) {
    console.error('Error uploading file:', err);
  }
};

// Function to delete an object from S3 using its key
export const deleteObjectByKey = async (key) => {
  try {
    const params = {
      Bucket: process.env.VUE_APP_AWS_BUCKET,
      Key: key,
    };

    const deleteUrl = `https://${process.env.VUE_APP_AWS_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${key}`;

    const response = await axios.delete(deleteUrl, {
      headers: {
        'x-amz-content-sha256': 'UNSIGNED-PAYLOAD',
        'x-amz-acl': 'public-read',
      },
    });

    console.log('Delete response:', response);
    return response;
  } catch (err) {
    console.error('Error deleting object:', err);
  }
};

// Function to get an object from S3 using its key
export const getObjectByKey = async (key) => {
  try {
    const getUrl = `https://${process.env.VUE_APP_AWS_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${key}`;

    const response = await axios.get(getUrl, {
      responseType: 'blob', // to get the file data as a binary object
    });

    console.log('Get object response:', response);
    return response;
  } catch (err) {
    console.error('Error getting object:', err);
  }
};
