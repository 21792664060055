<template>
<div :class="'userCard ' + type">

    <div class="userCardImg mr-6">
         <router-link to="/profile" v-if="link" class="">
        <img v-if="user.Avatarurl" :src="user.Avatarurl" alt="">
        <img v-else src="@/assets/imgs/noAvatar.svg" />
        </router-link>
        <div v-else>
        <img v-if="user.Avatarurl" :src="user.Avatarurl" alt="">
        <img v-else src="@/assets/imgs/noAvatar.svg" />
        </div>
    </div>
    <div class="userCardcont">
        <div class="role cCamp">
            {{user.Rolename}}
        </div>
        <div class="userName">
            {{user.Firstname}} <br>
            {{user.Lastname}}
        </div>
        <div class="position">
            {{user.Position}}
        </div>
        <router-link to="/profile" v-if="link" class="mt-2 linkPrime">
            Profil bearbeiten
        </router-link>
    </div>

</div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'medium'
        },
        link: {
            type: Boolean,
            default: true
        },
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {

        }
    }
}
</script>
