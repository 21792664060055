<template>
<div class="profileWrapper w-full">
    <div class="row mb-8 mw-md">
        <div class="col-md-6">
            <div class="secInd">
                Profil
            </div>
            <h1 class="mt-6 mb-8 greetingsCont">
                Profil Details
            </h1>

            <get-avatar form="big" type="rounded" :url="cUser.attributes.Avatarurl" />

        </div>
        <div class="col-md-6"></div>
    </div>
    <div class="row mb-8 mw-md">
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Vorname</label>
                <div class="displayUserInput" v-html="cUser.attributes.Firstname"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Nachname</label>
                <div class="displayUserInput" v-html="cUser.attributes.Lastname"></div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Email Adresse</label>
                <div class="displayUserInput" v-html="cUser.attributes.email"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Telefonnummer</label>
                <div class="displayUserInput" v-html="cUser.attributes.Tel"></div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Stellenbezeichnung</label>
                <div class="displayUserInput" v-html="cUser.attributes.Position"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="profileEditCont textInputM">
                <label>Rolle</label>
                <div class="displayUserInput" v-html="cUser.attributes.Rolename"></div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import GetAvatar from '../../components/user/getAvatar.vue';
import userCard from '../../components/user/userCard.vue';
import {
    getUserByID
} from "../../graphql/queries";

export default {
    props: {
        userId: {
            type: String,
        }
    },
    components: {
        userCard,
        GetAvatar
    },
    data() {
        return {
            cUser: {}
        }
    },
    apollo: {
        $loadingKey: 'loading',
        usersPermissionsUser: {
            query: getUserByID,
            variables() {
                return {
                    "id": this.userId
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                console.log(res);
                this.cUser = res.data.usersPermissionsUser.data;
            }
        },
    },

}
</script>
