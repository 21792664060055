import moment from "moment"
const formatDate = (date, format) => {
    if (format == 'de') {
        return moment(date).format('DD.MM.YYYY');
    } 
    else if(format == 'de_time') {
        return moment(date).format('DD.MM.YYYY HH:mm');
    }
    else if (format == 'iso') {
        if (date == '') {
            return moment().toISOString();
        } else {
            return moment(date).toISOString();
        }
    }
}


export default formatDate;