<template>
<div :class="[{'authLayout' : user},  {'modOpen' : modOpen !== false  || $store.state.mobileMenu === true},
 {'light' : cTheme} 
 ]">
    <div>
        <LeftNav v-if="user" />
    </div>
    <div :class="[{'contentWrap auth contentP' : user}]">
        <TopNav :user="user" v-if="user" />
             <router-view></router-view>
    </div>
</div>
</template>

<script>
import LeftNav from '@/components/framework/leftNav.vue';
import TopNav from '@/components/framework/topNav.vue';

export default {
    components: {
        LeftNav,
        TopNav
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        modOpen() {
            return this.$store.state.modOpen
        },
        cTheme() {
            return this.$store.state.cTheme
        }
    },
};
</script>
