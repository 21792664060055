<template>
<div class="uploadFilePageWrapper my-24"> 
    <FilePond ref="pond" :credits="false"  :server="{
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        uploadFile(file, metadata, load, error, progress, abort)
      },
    }" @removefile="onRemoveFile" />
</div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";


import { singleUpload, deleteObjectByKey } from '@/composables/aws.js'

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageTransform
);

export default {
    data() {
        return {}
    },
    components: {
        FilePond,
    },
    methods: {
        async onRemoveFile(event) {
            let url = '/' + event.file.name // event.file.name has only the name after the slash / so the actual filename
            const res = await deleteObjectByKey(url)
            if (
                res.$response.httpResponse.statusCode >= 200 &&
                res.$response.httpResponse.statusCode < 300
            ) {
                // here remove data from database too
            }
        },
        async uploadFile(file, metadata, load, error, progress, abort) {
            const result = await singleUpload(
                file,
                '' // folder of the file, you should change it to your variable or a string
            )
            console.log(result);
            if (result.status === 200) {
                // Handle storing it to your database here
                load(file) // Let FilePond know the processing is done
            } else {
                error() // Let FilePond know the upload was unsuccessful
            }
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    // Let FilePond know the request has been cancelled
                    abort()
                },
            }
        },
    },
    created() {
            // console.log(process.env.VUE_APP_AWS_ACCESS_SECRET);
            // console.log(process.env.VUE_APP_AWS_ACCESS_KEY_ID);
            // console.log(process.env.VUE_APP_AWS_REGION);
    }
}
</script>
