import gql from 'graphql-tag';


export const updateUser = gql `
mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id , data: $data ) {
      data {
        attributes {
          Firstname
          Lastname
          Tel
          Position
        }
      }
    }
  }`

export const updateAvatar = gql `
mutation updateAvatar($id:ID!, $data:UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id , data: $data ) {
   data {
    id
    attributes {
      Avatar {data {id}}
      Avatarurl
    }
  } 
  }
}`

export const uploadFSPFile = gql `
  mutation($file: Upload!) {
    upload(file: $file) {
      data {
        attributes {
          id
          name}
      }
    }
  }`
export const updateFileComments = gql `
  mutation updateFileComments($id:ID!, $data:FspfileInput!) {
    updateFspfile(id: $id , data: $data ) {
       data {
        id
        attributes {
          Comments
        }
      } 
      }
    }`
    
export const updateFileState = gql `
mutation updateFileState($id: ID!, $data: FspfileInput!) {
  updateFspfile(id: $id, data: $data) {
    data {
      id
      attributes {
        FileState
      }
    }
  }
}`


export const updateFileCollections = gql `
mutation updateFileCollections($id: ID!, $data: FspfileInput!) {
  updateFspfile(id: $id, data: $data) {
    data {
      id
      attributes {
        collections {
          data {
            id
            attributes {
              Name
            }
          }
        }
      }
    }
  }
}
`

export const updateFileCollection = gql `
mutation updateFileComments($id: ID!, $data: FspfileInput!) {
  updateFspfile(id: $id, data: $data) {
    data {
      id
      attributes {
        collections {
          data {
            id
          }
        }
      }
    }
  }
}
`



export const updateFspFile = gql `
mutation updateFspFile($ID:ID!, $data: FspfileInput!) {
  updateFspfile(id:$ID, data: $data) {
    data {
      attributes {
        Name
        Description
        Creator {
          data {
            id
          }
        }
        DescType
        Deadline
        containers {
          data {
            id
            attributes {
              Name
              sub_containers {
                data {
                  id
                  attributes {
                    Name
                  }
                }
              }
            }
          }
        }
        sub_containers {
          data {
            id
            attributes {
              Name
            }
          }
        }
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
        channels {
          data {
            id
            attributes {
              Name
            }
          }
        }
        materials {
          data {
            id
            attributes {
              Name
            }
          }
        }
        mediums {
          data {
            id
            attributes {
              Name
            }
          }
        }
        tags {
          data {
            id
            attributes {
              Name
            }
          }
        }
        fspFile {
          data {
            id
            attributes {
              name
              size
              url
              ext
              mime
            }
          }
        }
      }
    }
  }
}
`