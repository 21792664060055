import gql from 'graphql-tag';


export const createMedium = gql `
mutation createMedium($data: MediumInput!) {
    createMedium(data: $data ) {
      data {
        id
        attributes {
         Name
        }
      }
    }
  }
  `

export const createMaterial = gql `
mutation createMaterial($data: MaterialInput!) {
  createMaterial(data: $data) {
    data {
      id
      attributes {
        Name

      }
    }
  }
}
`


export const createTag = gql `
mutation createTag($data: TagInput!) {
  createTag(data: $data) {
    data {
      id
      attributes {
        Name

      }
    }
  }
}
`


export const createFspfile = gql `
mutation createFspfile($data: FspfileInput!) {
  createFspfile(data: $data) {
    data {
      id
      attributes {
        Name
        Description
        Creator {
          data {
            id
          }
        }
        DescType
        fspFileV2
        Deadline
        containers {
          data {
            id
            attributes {
              Name
              sub_containers {
                data {
                  id
                  attributes {
                    Name
                  }
                }
              }
            }
          }
        }
        Vorschaubild {
          data {
            id
            attributes {
              url
            }
          }
        }
        PrevLink 
        sub_containers {
          data {
            id
            attributes {
              Name
            }
          }
        }
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
        channels {
          data {
            id
            attributes {
              Name
            }
          }
        }
        materials {
          data {
            id
            attributes {
              Name
            }
          }
        }
        mediums {
          data {
            id
            attributes {
              Name
            }
          }
        }
        tags {
          data {
            id
            attributes {
              Name
            }
          }
        }
        fspFile {
          data {
            id
            attributes {
              name
              size
              url
              ext
              mime
            }
          }
        }
      }
    }
  }
}
`

export const createCollection = gql `
mutation createCollection($data:CollectionInput!) {
  createCollection(data:$data) {
  data {
    id
    attributes {
      Name
    }
  }
}
}`