<template>
<div class="uploadFilePageWrapper">
    <div class="">
        <div class="secInd inline-block">
            Datei bearbeiten
        </div>
    </div>
</div>
<div class="row fyCenter">
    <div class="col-md-6">
        <div class="fyCenter mt-8">
            <h1 class="">
                Dateien bearbeiten
            </h1>
        </div>
    </div>
    <div class="col-md-6">
        <div class="fxEnd">
            <div class="fyCenter">

                <div class="w-80">
                    <EditCollections v-model:sColls="sColls" />
                </div>
            </div>

        </div>
    </div>
</div>
<div v-if="!loading">
    <div v-if="loggedInUser.canUpload" class="uploadCont">
        <div class="row">
            <div class="col-md-6">
                <div class="uploadChooseContainer textInputM">
                    <div class="label">Name</div>
                    <input v-model="fileName" class="rounded-md" placeholder="Wenn leer wird der Dateiname übernommen" type="text">
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.conts}">Wähle den Speicherort</div>
                    <container-list form="select" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.departments}">Wähle das Department</div>
                    <department-list></department-list>
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.channels}">Wähle den Kanal</div>
                    <channel-list />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.mediums}">Wähle das Medium</div>
                    <medium-list :create="true" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.materials}">Wähle das Material</div>
                    <material-list :create="true" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label">Tags</div>
                    <tag-list :create="true" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label">Deadline</div>
                    <div class="uploadChooseWrap">
                        <div class="deadlineUploadWrapper">
                            <DatePicker color="red" is-expanded :minute-increment="15" title-position="left" :min-date="new Date()" show-weeknumbers is24hr trim-weeks is-dark locale="de" v-model="deadline" mode="dateTime" placeholder="Hover, um Deadline einzugeben">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="dateInput" :value="inputValue" v-on="inputEvents" />
                                </template>
                            </DatePicker>

                            <div @click="deadline = ''" :class="['addChoose',deadline == '' ? 'locked' : '']"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg></div>
                        </div>
                    </div>
                </div>
                <div class="uploadChooseContainer">
                    <div class="fxBetween">
                        <div class="label">Beschreibung</div>
                        <div @click="toggleImportant()" class="toogleWarning" :class="{'active' : DescType == 'warning'}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg> Wichtig
                        </div>
                    </div>
                    <div class="descriptionUploadWrap">
                        <textarea name="desc" id="descUpload" class="descUpload" v-model="descUpload" cols="30" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 text-center gridViewWrapper py-8">
                <div class="fileTilePrev mb-4">
                <prevFileImg :file="fPreview" />
            </div>
            <div class="h3 mb-4">Vorschau</div>
                <div class="p-8 w-full border-2 fCenter relative border-dashed bCamp 2xl:p-16 bgPresDark">
                    <input class="uploadFileZone" type="file" v-on:change="showChangePrev">
                </div>
                <!-- <file-preview class="h-300px" :f="fPreview"></file-preview> -->
            </div>
            <div class="col-md-6 h-full gridViewWrapper text-center py-8">
                <div class="fileTilePrev mb-4">
                <prevFileImg type="fileIcon" :file="fPreview" />
            </div>
            <div class="h3 mb-4">Datei</div>
                <div class="p-8 w-full border-2  fCenter relative border-dashed bCamp 2xl:p-32 bgPresDark">
                    <input class="uploadFileZone" type="file" v-on:change="showChange">
                </div>
            </div>
            <div class="col-12 mt-8">
                <div @click="submitFile" class="uploadFileBtn btnPrime">
                    SPEICHERN
                </div>
            </div>

        </div>
    </div>
    <div class="noRightUpload  mt-32" v-else>
        <h1>Leider hast du keine Berechtigung Dateien hochzuladen.</h1>
    </div>
    <div :class="['fspModal centerModal contModal', modOpen == 'showFile' ? 'activeMod' : '']">
        <div class="modal-cont-lg">
            <div class="modal-header">
                <h2 class="succesHeaderModal mx-auto mb-10">Datei erfolgreich gespeichert </h2>
                <div class="fxEnd -mt-5">
                    <div @click="openMod(false)" class="closeBtn">X</div>
                </div>
            </div>
        </div>
    </div>
    <div :class="['fspModal contModal', modOpen == 'uploadProgress' ? 'activeMod' : '']">
        <div class="modal-cont-lg text-center">
            <div class="modal-header">
                <h2 class="succesHeaderModal text-center mx-auto mb-5">Upload gestartet!</h2>
            </div>
            <div class="modal-body">
                <div class="uploadBar">
                    {{barUpload}}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import filePreview from '../../components/filesystem/filePreview.vue'
import ShowFile from '../../components/filesystem/showFile.vue';
import ContainerList from '../../components/meta/containerList.vue';
import DepartmentList from '../../components/meta/departmentList.vue';
import ChannelList from '../../components/meta/channelList.vue';
import MediumList from '../../components/meta/mediumList.vue';
import MaterialList from '../../components/meta/materialList.vue';
import TagList from '../../components/meta/tagList.vue';
import prevFileImg from '@/components/filesystem/prevFileImg.vue';
import formatDate from '@/composables/formats.js';
import { updateFspFile } from '@/graphql/mutations';
import {
    getFspFile
} from '@/graphql/queries'
import EditCollections from '@/components/filesystem/editCollections.vue';

export default {
    props: {
        fileId: {
            type: String,
        }
    },
    components: {
        ShowFile,
        ContainerList,
        DepartmentList,
        ChannelList,
        MediumList,
        MaterialList,
        TagList,
        EditCollections,
        filePreview,
        prevFileImg
    },
    data() {
        return {
            cContainers: null,
            cConts: null,
            cSubs: null,
            descUpload: '',
            loading: true,
            uploadFiles: [],
            fileRecordsForUpload: [],
            fileRecordsForUploadPrev: [],
            fileTransfered: false,
            transferedFile: {},
            fileName: '',
            deadline: '',
            DescType: '',
            barUpload: 0,
            modOpen: false,
            validation: {
                conts: true,
                departments: true,
                channels: true,
                mediums: true,
                materials: true,
            },
            sColls: [],
            keepData: false,
            fPreview: {}

        }
    },
    apollo: {
        fspfile: {
            query: getFspFile,
            variables() {
                return {
                    "id": parseInt(this.fileId)
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                console.log(res);
                const fePath = res.data.fspfile.data.attributes;
                //    this.$store.dispatch('updateToggleContainer', id);
                fePath.channels.data.forEach(c => {
                    this.$store.dispatch('updateToggleChans', c.id);
                });
                fePath.departments.data.forEach(c => {
                    this.$store.dispatch('updateToggleDeps', c.id);
                });
                fePath.mediums.data.forEach(c => {
                    this.$store.dispatch('updateToggleMeds', c.id);
                });
                fePath.materials.data.forEach(c => {
                    this.$store.dispatch('updateToggleMats', c.id);
                });
                fePath.tags.data.forEach(c => {
                    this.$store.dispatch('updateToggleTags', c.id);
                });
                this.deadline = fePath.Deadline;
                this.DescType = fePath.DescType;
                // this.sColls = fePath.collections;
                console.log(fePath.collections);
                const nColls = [];
                fePath.collections.data.forEach(c => {
                    let nColl = {
                        "id": c.id,
                        "label": c.attributes.Name
                    }
                    nColls.push(nColl);
                });
                this.sColls = nColls;

                this.descUpload = fePath.Description;
                this.fileName = fePath.Name;
                this.loading = false;
                // console.log(this.fspfile);
                // this.fPreview = res.data.fspfile.data.attributes;
                this.fPreview = this.tFile( res.data.fspfile );
                console.log(this.fPreview);

            }
        },
    },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        },

    },
    methods: {
        tFile(res) {
            console.log(res);
            let tFileTemp = {
                "url": "",
                "name": "",
                "size": 0,
                "ext": "",
                "version": "v1",
                "prevUrl": ""
            }
            if(res.data.attributes.PrevLink != '') {
                tFileTemp.prevUrl = res.data.attributes.PrevLink;
            }
            if (res.data.attributes.fspFileLink != '' && res.data.attributes.fspFileLink != null) {
                tFileTemp.url = res.data.attributes.fspFileLink;
                tFileTemp.version = "v2";
                tFileTemp.ext = res.data.attributes.fspFileLink.split(/[#?]/)[0].split('.').pop().trim();
                tFileTemp.size = "Unbekannt";
            } else if (res.data.attributes.fspFileV2 != '' && res.data.attributes.fspFileV2 != null) {
                tFileTemp.url = res.data.attributes.fspFileV2.url;
                tFileTemp.name = res.data.attributes.fspFileV2.name;
                tFileTemp.size = res.data.attributes.fspFileV2.size;
                tFileTemp.version = "v2";
                tFileTemp.ext = res.data.attributes.fspFileV2.name.substring(res.data.attributes.fspFileV2.name.lastIndexOf("."));
            } else {
                tFileTemp.url = res.data.attributes.fspFile.data.attributes.url;
                tFileTemp.name = res.data.attributes.fspFile.data.attributes.name;
                tFileTemp.size = res.data.attributes.fspFile.data.attributes.size;
                tFileTemp.ext = res.data.attributes.fspFile.data.attributes.ext;
                tFileTemp.version = "v2";
            }

            return tFileTemp;

        },
        onProgress(percent) {
            this.barUpload = percent;
        },
        toggleImportant() {
            if (this.DescType == 'warning') {
                this.DescType = '';
            } else {
                this.DescType = 'warning'
            }
        },
        openMod(state) {
            this.modOpen = state;
            this.$store.dispatch('openMod', state);
        },
        showChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            console.log(files[0]);
            this.fileRecordsForUpload[0] = files[0];
        },
        showChangePrev(e) {
            var files = e.target.files || e.dataTransfer.files;
            console.log(files[0]);
            this.fileRecordsForUploadPrev[0] = files[0];
        },
        submitFile() {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer ' + this.$store.state.user.jwt
                },
                onUploadProgress: event => {
                    if (event.loaded * 100 < 100) {
                        this.barUpload = Math.round(
                            (event.loaded * 100) / event.total
                        ) + '%';
                    } else {
                        this.barUpload = 'Datei erfolgreich hochgeladen. Metadaten werden eingefügt...';
                    }

                },
            }
            if (this.$store.state.cContainers.toString().includes('s_')) {
                let id = this.$store.state.cContainers.toString();
                id = id.replace('s_', '');
                this.cSubs = id;
            } else {
                this.cConts = this.$store.state.cContainers;
            }
            if (this.validUpload() === true) {
                var collIds = this.sColls.map(c => c.id);
                if (this.DescType == '') {
                    this.DescType = 'info';
                }

                if (this.fileRecordsForUpload.length) {
                    console.log('inLength');

                    this.openMod('uploadProgress');

                    let formData = new FormData();
                    formData.append('files', this.fileRecordsForUpload[0]);
                    axios.post("https://fspback.brotsalz.de/api/upload",
                            formData, config
                        )
                        .then(res => {
                            var prevUrl = '';

                            if (res.data[0].mime == "image/png" || res.data[0].mime == "image/jpg" || res.data[0].mime == "image/jpeg") {
                                prevUrl = res.data[0].url.replace('.amazonaws.com/', '.amazonaws.com/small_');
                            }

                            this.barUpload = 'Datei erfolgreich hochgeladen. Metadaten werden eingefügt...';
                            if (this.fileName == '') {
                                this.fileName = res.data[0].name;
                            }

                            this.$apollo.mutate({
                                mutation: updateFspFile,
                                context: {
                                    headers: {
                                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                                    },
                                },
                                variables: {
                                    "ID": parseInt(this.fileId),
                                    "data": {
                                        Name: this.fileName,
                                        Description: this.descUpload,
                                        DescType: this.DescType,
                                        containers: this.cConts,
                                        sub_containers: this.cSubs,
                                        departments: this.$store.state.cDepartments,
                                        materials: this.$store.state.cMa,
                                        mediums: this.$store.state.cMeds,
                                        channels: this.$store.state.cChans,
                                        fspFile: res.data[0].id,
                                        tags: this.$store.state.cTags,
                                        publishedAt: formatDate('', 'iso'),
                                        Deadline: formatDate(this.deadline, 'iso'),
                                        PrevLink: prevUrl,
                                        Creator: this.$store.state.user.user.id.toString(),
                                        collections: collIds

                                    }

                                }
                            }).then((res) => {

                                this.openMod('showFile');

                            });

                        })
                        .catch(err => {
                            console.error(err);
                        });

                } else {
                    if (this.fileRecordsForUploadPrev.length) {
                        let formDataPrev = new FormData();
                        formDataPrev.append('files', this.fileRecordsForUploadPrev[0]);
                        axios.post("https://fspback.brotsalz.de/api/upload",
                                formDataPrev, config
                            )
                            .then(res => {
                                let vars = {
                                    "ID": parseInt(this.fileId),
                                    "data": {
                                        "Vorschaubild": res.data[0].id
                                    }
                                }
                                this.$apollo.mutate({
                                    mutation: updateFspFile,
                                    context: {
                                        headers: {
                                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                                        },
                                    },
                                    variables: vars
                                }).then((res) => {
                                    this.$apollo.queries.fspfile.refetch();
                                });

                            });

                    }
                    let vars = {
                        "ID": parseInt(this.fileId),
                        "data": {
                            Name: this.fileName,
                            Description: this.descUpload,
                            DescType: this.DescType,
                            containers: this.cConts,
                            sub_containers: this.cSubs,
                            departments: this.$store.state.cDepartments,
                            materials: this.$store.state.cMa,
                            mediums: this.$store.state.cMeds,
                            channels: this.$store.state.cChans,
                            tags: this.$store.state.cTags,
                            publishedAt: formatDate('', 'iso'),
                            Deadline: formatDate(this.deadline, 'iso'),
                            Creator: this.$store.state.user.user.id.toString(),
                            collections: collIds

                        }

                    };
                    this.$apollo.mutate({
                        mutation: updateFspFile,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: vars
                    }).then((res) => {
                        this.openMod('showFile');
                    });
                }
            }
        },
        validUpload() {
            let isValid = true;
            Object.keys(this.validation).forEach(key => {
                this.validation[key] = true;
            });

            if (this.cSubs == '' || this.cConts == '') {
                this.validation.conts = false;
                isValid = false;
            }
            if (this.$store.state.cDepartments.length < 1) {
                this.validation.departments = false;
                isValid = false;
            }
            if (this.$store.state.cMeds.length < 1) {
                this.validation.mediums = false;
                isValid = false;
            }
            if (this.$store.state.cMa.length < 1) {
                this.validation.materials = false;
                isValid = false;
            }
            if (this.$store.state.cChans.length < 1) {
                this.validation.channels = false;
                isValid = false;
            }
            return isValid;
        },

    },
    unmounted() {
        this.openMod(false);
        this.$store.dispatch('clearUploadStates');
    },
}
</script>


<style>
.centerModal {
    align-items: center;
}
</style>