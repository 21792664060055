import axios from 'axios';

const notifyMail = (data) => {
    var params = new URLSearchParams();
    let sData = JSON.stringify(data);

    params.append('mail', 'on');
    params.append('data', sData);

    axios.post("https://fspassets.brotsalz.de/mailing/mailer.php", params);

    return "send";

}


export default notifyMail;