<template>
<div class="uploadFilePageWrapper">
    <div class="">
        <div class="secInd inline-block">
            UPLOAD
        </div>
    </div>
</div>
<div class="row fyCenter">
    <div class="col-md-6">
        <div class="fyCenter justify-between w-full mt-8">
            <h1 class="">
                Dateien hochladen
            </h1>
            <div class="fyCenter fxEnd">
                <div class="ml-4">
                    <div class="themeSwitcher">
                        <input v-model="notifyDepartment" class="form-check-input appearance-none w-9 rounded-full mx-4 h-5 align-top bg-white bg-no-repeat bg-contain  border-2 focus:outline-none cursor-pointer shadow-sm" :class="[notifyDepartment ? 'bgCamp bCamp' :  'bgPresent bWhite' ]" type="checkbox" role="switch" id="themeSwitch">
                        <div class="inline-block">Department(s) benachrichtigen</div>
                    </div>
                </div>
                <div class="ml-4">
                    <div class="themeSwitcher">
                        <input v-model="keepData" class="form-check-input appearance-none w-9 rounded-full mx-4 h-5 align-top bg-white bg-no-repeat bg-contain  border-2 focus:outline-none cursor-pointer shadow-sm" :class="[keepData ? 'bgCamp bCamp' :  'bgPresent bWhite' ]" type="checkbox" role="switch" id="themeSwitch">
                        <div class="inline-block">Auswahl behalten</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="fxEnd">
            <div class="fyCenter">

                <div class="w-80">
                    <EditCollections @sColls="updateColls" v-model:sColls="sColls" />
                </div>
            </div>

        </div>
    </div>
</div>
<div v-if="!loading">
    <div v-if="loggedInUser.canUpload" class="uploadCont">
        <div class="row">
            <div class="col-md-6">
                <div class="uploadChooseContainer textInputM">
                    <div class="label">Name</div>
                    <input v-model="fileName" class="rounded-md" placeholder="Wenn leer wird der Dateiname übernommen" type="text">
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.conts}">Wähle den Speicherort</div>
                    <container-list form="select" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.departments}">Wähle das Department</div>
                    <department-list></department-list>
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.channels}">Wähle den Kanal</div>
                    <channel-list />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.mediums}">Wähle das Medium</div>
                    <medium-list :create="true" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="uploadChooseContainer">
                    <div class="label" :class="{'emptyChoice' : !validation.materials}">Wähle das Material</div>
                    <material-list :create="true" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label">Tags</div>
                    <tag-list :create="true" />
                </div>
                <div class="uploadChooseContainer">
                    <div class="label">Deadline</div>
                    <div class="uploadChooseWrap">
                        <div class="deadlineUploadWrapper">
                            <DatePicker color="red" is-expanded :minute-increment="15" title-position="left" :min-date="new Date()" show-weeknumbers is24hr trim-weeks is-dark locale="de" v-model="deadline" mode="dateTime" placeholder="Hover, um Deadline einzugeben">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="dateInput" :value="inputValue" v-on="inputEvents" />
                                </template>
                            </DatePicker>

                            <div @click="deadline = ''" :class="['addChoose',deadline == '' ? 'locked' : '']"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg></div>
                        </div>
                    </div>
                </div>
                <div class="uploadChooseContainer">
                    <div class="fxBetween">
                        <div class="label">Beschreibung</div>
                        <div @click="toggleImportant()" class="toogleWarning" :class="{'active' : DescType == 'warning'}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg> Wichtig
                        </div>
                    </div>
                    <div class="descriptionUploadWrap">
                        <textarea name="desc" id="descUpload" class="descUpload" v-model="descUpload" cols="30" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center py-8">
                <div class="text-left mt-8 mb-4 text-2xl font-bold">
                    Vorschaubild
                </div>
                <div class="p-8 w-full border-2 fCenter relative border-dashed bCamp 2xl:p-16 bgPresDark">
                    <input class="uploadFileZone" type="file" v-on:change="showChangePrev">
                </div>
            </div>
            <div class="col-12 text-center py-8">
                <div class="text-left mt-8 mb-4 text-2xl font-bold">
                    Datei
                </div>
                <div class="p-16 w-full border-2 fCenter relative border-dashed bCamp 2xl:p-32 bgPresDark">
                    <div>
                        <input class="uploadFileZone" type="file" ref="mainfileUpload" v-on:change="showChange">
                        <div class="p-16 text-center">
                            -- oder --
                        </div>
                        <div class="textInputM">
                            <input type="text" class="" placeholder="Link einfügen">
                        </div>
                    </div>
                </div>
                <div @click="submitFile" class="uploadFileBtn btnPrime">
                    DATEIEN HOCHLADEN
                </div>
            </div>
        </div>
    </div>
    <div class="noRightUpload  mt-32" v-else>
        <h1>Leider hast du keine Berechtigung Dateien hochzuladen.</h1>
    </div>
    <div :class="['fspModal contModal', modOpen == 'showFile' ? 'activeMod' : '']">
        <div class="modal-cont-lg">
            <div class="modal-header">
                <h2 class="succesHeaderModal mx-auto mb-10">Datei Erfolgreich hochgeladen</h2>
                <div class="fxEnd -mt-5">
                    <div @click="openMod(false)" class="closeBtn">X</div>
                </div>
            </div>
            <div class="modal-body">
                <div v-if="fileTransfered">
                    <show-file :hasState="false" :file="transferedFile" />
                </div>
            </div>
        </div>
    </div>
    <div :class="['fspModal contModal', modOpen == 'uploadProgress' ? 'activeMod' : '']">
        <div class="modal-cont-lg text-center">
            <div class="modal-header">
                <h2 class="succesHeaderModal text-center mx-auto mb-5">Upload gestartet!</h2>
            </div>
            <div class="modal-body">
                <div class="uploadBar hidden">
                    {{barUpload}}
                </div>
                <div class="uploadSpark">
                    <img src="../assets/imgs/spark.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="testbtn" @click="test">Test</div>
</div>
</template>

<script>
import axios from 'axios';
import ShowFile from '../components/filesystem/showFile.vue';
import ContainerList from '../components/meta/containerList.vue';
import DepartmentList from '../components/meta/departmentList.vue';
import ChannelList from '../components/meta/channelList.vue';
import MediumList from '../components/meta/mediumList.vue';
import MaterialList from '../components/meta/materialList.vue';
import TagList from '../components/meta/tagList.vue';
import formatDate from '@/composables/formats.js';
import { createFspfile } from '@/graphql/create';
import EditCollections from '@/components/filesystem/editCollections.vue';
import notifyMail from '@/composables/mailing';

export default {
    components: {
        ShowFile,
        ContainerList,
        DepartmentList,
        ChannelList,
        MediumList,
        MaterialList,
        TagList,
        EditCollections
    },

    data() {
        return {
            cContainers: null,
            cConts: null,
            cSubs: null,
            descUpload: '',
            loading: 0,
            uploadFiles: [],
            fileRecordsForUpload: [],
            fileRecordsForUploadPrev: [],
            fileTransfered: false,
            transferedFile: {},
            fileName: '',
            deadline: '',
            DescType: '',
            barUpload: 0,
            modOpen: false,
            validation: {
                conts: true,
                departments: true,
                channels: true,
                mediums: true,
                materials: true,
            },
            sColls: [],
            keepData: false,
            notifyDepartment: true

        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        },

    },
    methods: {
        onProgress(percent) {
            this.barUpload = percent;
        },
        toggleImportant() {
            if (this.DescType == 'warning') {
                this.DescType = '';
            } else {
                this.DescType = 'warning'
            }
        },
        openMod(state) {
            this.modOpen = state;
            this.$store.dispatch('openMod', state);
        },
        showChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (files[0].size > 1024 * 1024 * 142) {
                alert('Die Datei ist zu groß für die Amazon SDK. Bitte wende dich an jan@brotsalz.de');
                this.$refs.mainfileUpload.value = null;
            } else {
                this.fileRecordsForUpload[0] = files[0];
            }

        },
        updateColls(e) {
            this.sColls = e;
        },
        showChangePrev(e) {
            var files = e.target.files || e.dataTransfer.files;
            this.fileRecordsForUploadPrev[0] = files[0];
        },
        submitFile() {
            if (this.$store.state.cContainers.toString().includes('s_')) {
                let id = this.$store.state.cContainers.toString();
                id = id.replace('s_', '');
                this.cSubs = id;
            } else {
                this.cConts = this.$store.state.cContainers;
            }
            if (this.validUpload() === true) {
                this.openMod('uploadProgress');
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                    onUploadProgress: event => {
                        if (event.loaded * 100 < 100) {
                            this.barUpload = Math.round(
                                (event.loaded * 100) / event.total
                            ) + '%';
                        } else {
                            this.barUpload = 'Datei erfolgreich hochgeladen. Metadaten werden eingefügt...';
                        }

                    },
                }
                if (this.DescType == '') {
                    this.DescType = 'info';
                }
                var collIds = this.sColls.map(c => c.id);
                var depIds = this.$store.state.cDepartments.map(c => c.id);
                let vars = {
                    "data": {
                        Name: '',
                        Description: this.descUpload,
                        DescType: this.DescType,
                        containers: this.cConts,
                        sub_containers: this.cSubs,
                        departments: depIds,
                        materials: this.$store.state.cMa,
                        mediums: this.$store.state.cMeds,
                        channels: this.$store.state.cChans,
                        fspFile: 0,
                        Vorschaubild: null,
                        tags: this.$store.state.cTags,
                        publishedAt: formatDate('', 'iso'),
                        Deadline: formatDate(this.deadline, 'iso'),
                        Creator: this.$store.state.user.user.id.toString(),
                        collections: collIds

                    }
                };
                let formData = new FormData();
                formData.append('files', this.fileRecordsForUpload[0]);
                axios.post("https://fspback.brotsalz.de/api/upload",
                        formData, config
                    )
                    .then(res => {
                        if (this.fileName == '') {
                            this.fileName = res.data[0].name;
                        }
                        vars.data.Name = this.fileName;
                        vars.data.fspFile = res.data[0].id;
                        if (this.fileRecordsForUploadPrev.length) {
                            let prevDataPrev = new FormData();
                            prevDataPrev.append('files', this.fileRecordsForUploadPrev[0]);
                            axios.post("https://fspback.brotsalz.de/api/upload",
                                    prevDataPrev, config
                                )
                                .then(prev => {
                                    let prevId = prev.data[0].id;
                                    vars.data.Vorschaubild = prevId;
                                    this.barUpload = 'Datei erfolgreich hochgeladen. Metadaten werden eingefügt...';
                                    console.log(vars);
                                    this.$apollo.mutate({
                                        mutation: createFspfile,
                                        context: {
                                            headers: {
                                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                                            },
                                        },
                                        variables: vars
                                    }).then((res) => {
                                        this.transferedFile = res.data.createFspfile;
                                        this.restoreStates();
                                    });
                                })
                                .catch(err => {
                                    console.error(err);
                                });
                        } else {
                            this.barUpload = 'Datei erfolgreich hochgeladen. Metadaten werden eingefügt...';
                            console.log(vars);
                            this.$apollo.mutate({
                                mutation: createFspfile,
                                context: {
                                    headers: {
                                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                                    },
                                },
                                variables: vars
                            }).then((res) => {
                                this.transferedFile = res.data.createFspfile;
                                let data = {
                                    rec: [],
                                    file: [{
                                        "name": "hi",
                                        "id": 1
                                    }]
                                };
                                if (this.notifyDepartment === true) {
                                    this.$store.state.cDepartments.forEach(d => {
                                        d.attributes.users.data.forEach(u => {
                                            let user = {
                                                "id": u.id,
                                                "name": u.attributes.username,
                                                "mail": u.attributes.email
                                            }
                                            data.rec.push(user);
                                        })
                                    });
                                    if (data.rec.length) {
                                        data.file[0] = {
                                            "name": res.data.createFspfile.data.attributes.Name,
                                            "id": res.data.createFspfile.data.id
                                        };
                                        notifyMail(data);
                                    }
                                }
                                this.restoreStates();
                            });
                        }
                    });
            }
        },
        restoreStates() {
            console.log('IN');
            this.fileTransfered = true;
            this.fileName = '';
            this.fileRecordsForUpload[0] = null;
            this.fileRecordsForUploadPrev[0] = null;

            this.openMod('showFile');
            if (this.keepData === false) {
                this.descUpload = '';
                this.DescType = '';
                this.cConts = '';
                this.cSubs = '';
                this.$store.state.cContainers = 1;
                this.$store.state.cDepartments = [];
                this.$store.state.cMa = [];
                this.$store.state.cMeds = [];
                this.$store.state.cChans = [];
                this.fileRecordsForUpload = [];
                this.$store.state.cTags = [];
                this.deadline = '';
            }
        },
        validUpload() {
            let isValid = true;
            Object.keys(this.validation).forEach(key => {
                this.validation[key] = true;
            });

            if (this.cSubs == '' || this.cConts == '') {
                this.validation.conts = false;
                isValid = false;
            }
            if (this.$store.state.cDepartments.length < 1) {
                this.validation.departments = false;
                isValid = false;
            }
            if (this.$store.state.cMeds.length < 1) {
                this.validation.mediums = false;
                isValid = false;
            }
            if (this.$store.state.cChans.length < 1) {
                this.validation.channels = false;
                isValid = false;
            }
            return isValid;
        },
        sendMail() {

        },
        getMailData() {
            let data = {
                rec: [],
                file: [{
                    "name": "hi",
                    "id": 1
                }]
            };
            this.$store.state.cDepartments.forEach(d => {
                d.attributes.users.data.forEach(u => {
                    let user = {
                        "id": u.id,
                        "name": u.attributes.username,
                        "mail": u.attributes.email
                    }
                    data.rec.push(user);
                })
            });
            notifyMail(data);
        },
        test() {
            // let data = this.getMailData();
            // notifyMail(data);
            if (this.$store.state.cContainers.toString().includes('s_')) {
                let id = this.$store.state.cContainers.toString();
                id = id.replace('s_', '');
                this.cSubs = id;
            } else {
                this.cConts = this.$store.state.cContainers;
            }
            if (this.DescType == '') {
                this.DescType = 'info';
            }
            var collIds = this.sColls.map(c => c.id);
            var depIds = this.$store.state.cDepartments.map(c => c.id);
            let vars = {
                "data": {
                    Name: '',
                    Description: this.descUpload,
                    DescType: this.DescType,
                    containers: this.cConts,
                    sub_containers: this.cSubs,
                    departments: depIds,
                    materials: this.$store.state.cMa,
                    mediums: this.$store.state.cMeds,
                    channels: this.$store.state.cChans,
                    fspFile: 0,
                    Vorschaubild: null,
                    tags: this.$store.state.cTags,
                    publishedAt: formatDate('', 'iso'),
                    Deadline: formatDate(this.deadline, 'iso'),
                    Creator: this.$store.state.user.user.id.toString(),
                    collections: collIds

                }
            };

            console.log(vars);
        }
    },
    created() {
        this.$store.dispatch('clearUploadStates');
    },
    unmounted() {
        this.openMod(false);
        this.$store.dispatch('clearUploadStates');
    },
}

</script>
