<template>
<div v-if="!loading" class="ContainerView row rightContTemp">
    <div class="w-full">
        <div class="secInd">
            User
        </div>
        <h1 class="greetingsCont text-6xl">
            Nutzerübersicht
        </h1>
    </div>

    <div class="w-full my-4">
        <div v-for="u in users" :key="u.id" class="userListRow  row filesView">
            <div class="listRecentColFilePrev">

            </div>
            <div class="fileTileCol userNameCol h-16 fyCenter">
                <router-link class="fyCenter my-4" :to="'user/userById/'+u.id">

                    <get-avatar :type="'smaller'" :form="'round'" :url="u.attributes.Avatarurl" />
                    {{u.attributes.Firstname}} {{u.attributes.Lastname}}
                </router-link>
            </div>
            <div class="userDateCol">

                {{u.attributes.Rolename}}
            </div>
            <div class="userDateCol">

                {{u.attributes.Tel}}
            </div>

            <div class="userDateCol">

                {{u.attributes.Position}}
            </div>
            <div class="userDateCol">
                {{u.attributes.email}}
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    getUsers
} from '@/graphql/queries'
import getAvatar from '../components/user/getAvatar.vue';
export default {
    components: { getAvatar },
    data() {
        return {
            users: []
        }
    },
    apollo: {
        $loadingKey: 'loading',
        usersPermissionsUsers: {
            query: getUsers,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                this.users = res.data.usersPermissionsUsers.data;
            }
        },
    },
}
</script>
