<template>
<div v-if="!loading" class="groupShowTemplate rightContTemp">
    <div class="row">
        <div class="w-full">
            <div class="secInd">
                Gruppe
            </div>
            <h1 class="greetingsCont text-6xl">
                {{group.data.attributes.Name}}
            </h1>
        </div>
        <div class="w-full my-4">
            <div class="filesInGroupWrapper">
                <files-view @updateCollections="fileRefetch" :isInGroup="group.data.id" headerlabel="Dateien in der Gruppe" view="grid" :files="group.data.attributes.fspfiles.data" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { getFspGroup } from '@/graphql/queries'

import filesView from '../../components/filesystem/filesView.vue'
export default {
    components: { filesView },
    props: {
        groupId: {
            type: String,
        }
    },
    data() {
        return {
            loading: true,
            group: null
        }

    },
    apollo: {
        collection: {
            query: getFspGroup,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            variables() {
                return {
                    "id": parseInt(this.groupId)
                }
            },
            prefetch: true,
            result(res) {
                console.log(res);
                this.group = res.data.collection;
                this.loading = false;
            }
        },
    },
    methods: {
        fileRefetch() {
            this.$apollo.queries.collection.refetch()
        }
    }
}
</script>
