<template>
<div v-if="!loading" class="containerList">
    <div class="uploadChooseWrap">
        <div @click="toggleActive(ch.id)" v-for="ch in channels.data" :key="ch.id" :class="{'active' : isActive(ch.id)}" class="btnChoose">
            {{ch.attributes.Name}}
        </div>
    </div>
</div>
</template>

<script>
import {
    getChannels,
} from '@/graphql/queries';
export default {
    apollo: {
        channels: {
            query: getChannels,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result() {
                this.loading = false;
            }
        },
    },
    data() {
        return {
            cchannels: [],
            loading: true
        }
    },
    methods: {
        toggleActive(id) {
            this.$store.dispatch('updateToggleChans', id);
        },
        isActive(id) {
            if (this.$store.state.cChans.includes(id)) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>
