<template>
<div class="containerList">
    <div v-if="!loading" class="uploadChooseWrap">
        <div @click="toggleActive(m.id)" v-for="m in mediums.data" :key="m.id" :class="{'active' : isActive(m.id)}" class="btnChoose">
            {{m.attributes.Name}}
        </div>
        <div v-if="create" @click="openMod(true)" class="addChoose">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.152" height="11.48" viewBox="0 0 12.152 11.48">
                <path id="Path_13176" data-name="Path 13176" d="M.924-8.4H5.992v4.816H8.008V-8.4h5.068v-1.848H8.008v-4.816H5.992v4.816H.924Z" transform="translate(-0.924 15.064)" fill="#f97267" />
            </svg>
        </div>
        <div v-if="create" :class="['fspModal contModal', modOpen ? 'activeMod' : '']">
            <div class="modal-cont-sm text-center">
                <div class="fxEnd -mt-5">
                    <div @click="openMod(false)" class="closeBtn">X</div>
                </div>
                <div class="modalAddHeading">Neues Medium hinzufügen</div>
                <div class="textInputM">
                    <input v-model="addMediumName" type="text" class="">
                    <div @click="addMedium()" class="btnOutline mt-4 smaller">
                        Hinzufügen
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getMediums
} from '@/graphql/queries';
import {
    createMedium
} from '@/graphql/create';
import formatDate from '@/composables/formats'

export default {
    props: {
        create: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        $loadingKey: 'loading',
        mediums: {
            query: getMediums,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result() {}
        },
    },
    data() {
        return {
            cmediums: [],
            addMediumName: '',
            loading: 0,
            modOpen: false
        }
    },
    methods: {
        toggleActive(id) {
            this.$store.dispatch('updateToggleMeds', id);
        },
        openMod(state) {
            this.modOpen = state;
            this.$store.dispatch('openMod', state);
        },
        isActive(id) {
            if (this.$store.state.cMeds.includes(id)) {
                return true;
            } else {
                return false;
            }
        },
        addMedium() {
            if (this.addMediumName != '' && this.isDuplicate() !== true) {
                this.$apollo.mutate({
                        mutation: createMedium,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "data": {
                                "Name": this.addMediumName,
                                "publishedAt": formatDate('', 'iso')
                            }

                        },

                    })
                    .then((res) => {
                        this.toggleActive(res.data.createMedium.data.id)
                        this.$apollo.queries.mediums.refetch()

                        this.addMediumName = '';
                        this.openMod(false);

                    });
            }

        },
        isDuplicate() {
            var isTrue = false;
            this.mediums.data.every(e => {
                if (this.addMediumName.toLowerCase() == e.attributes.Name.toLowerCase()) {
                    this.toggleActive(e.id);
                    this.addMediumName = '';
                    this.openMod(false);
                    isTrue = true;
                    return false;
                } else {
                    return true;
                }
            });
            return isTrue;
        },
    }
}
</script>
