import prettyBytes from 'pretty-bytes'


const fileSizing = (size) => {
    if(size !== "Unbekannt") {
        return prettyBytes(size);
} else {
    return "Unbekannt";
}
}


export default fileSizing;