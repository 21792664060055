<template>
<div class="fspLoaderWrapper relative md:w-20 md:h-20">
    <svg class="absStretch" xmlns="http://www.w3.org/2000/svg" width="136.291" height="157.776" viewBox="0 0 136.291 157.776" style="">
        <g id="puschel" transform="translate(-6.443 -7.381)">
            <path id="Path_1" data-name="Path 1" d="M69.04,76.52c9.63-8.39,18.27-17.48,27.88-25.58,8.02-6.76,16.22-13.28,25.81-17.78,3.94-1.85,7.96-3.43,12.45-2.98,6.16.61,8.36,5.12,4.83,10.17-3.15,4.49-7.9,7.08-12.57,9.61-14.8,8.02-29.28,16.5-41.96,27.69a30.8,30.8,0,0,0-8.55,11.09c6.2-3.17,11.27-5.79,16.37-8.38,8.89-4.52,17.84-8.93,27.09-12.69a20.619,20.619,0,0,1,9.24-1.69c3.98.26,7.44,1.55,8.26,5.8.75,3.86-1.93,6.19-5.15,7.61a63.7,63.7,0,0,1-7.99,2.72c-8.14,2.35-16.38,4.41-24.46,6.96C76.31,96.62,60.74,112.16,55.18,137c-2.09,9.32-4.2,18.64-6.56,27.91.7-37,3.91-73.56,19.62-107.81,6.02-13.13,14.14-24.79,25.58-33.91,4.52-3.6,9.4-6.28,15.47-5.88,1.92.13,3.76.5,4.69,2.29,1.06,2.04.13,3.94-1.26,5.47a33.634,33.634,0,0,1-4.11,3.6A150.71,150.71,0,0,0,72.58,69.61C71.25,71.82,70.21,74.21,69.04,76.52Z" fill="none" stroke="#000" stroke-width="2" class="otLUcWWY_0"></path>
            <path id="Path_2" data-name="Path 2" d="M50.07,42.8c.62-8.62.75-16,2.18-23.25a26.684,26.684,0,0,1,2.88-8.42c.95-1.64,2.17-3.1,4.33-2.68a4.8,4.8,0,0,1,3.93,4c.77,3.35.06,6.58-.56,9.84-1,5.18-1.95,10.37-3.04,16.21,2.27-1.08,2.59-2.76,3.32-4.02,3.32-5.75,6.57-11.53,11.35-16.27,1.83-1.81,3.84-3.18,6.52-3.19,1.5-.01,3.13-.03,3.99,1.47a3.637,3.637,0,0,1-.57,4.17c-1.5,1.99-3.05,3.93-4.61,5.87A140.474,140.474,0,0,0,53.98,76.01c-.35,1.22-.84,2.4-1.75,4.94-1.56-5.78-2.74-10.31-4.01-14.81-3.21-11.38-7.21-22.35-15.2-31.51-2.28-2.61-5.22-6.55-1.5-9.54,3.85-3.09,7.04.34,9.56,3.37C44.44,32.5,46.67,37.29,50.07,42.8Z" fill="none" stroke="#000" stroke-width="2" class="otLUcWWY_1"></path>
            <path id="Path_3" data-name="Path 3" d="M31.53,84.16c-4.64-6.62-10.88-9.88-17.18-13.07A48.124,48.124,0,0,1,9.5,68.6c-1.41-.94-2.48-2.35-1.85-4.23a4.409,4.409,0,0,1,4.02-3.2c3.78-.38,6.77,1.41,9.62,3.45,5.85,4.17,10.64,9.47,15.52,14.75A49.568,49.568,0,0,0,22.29,54.7a16.778,16.778,0,0,1-4.75-6.29,4.93,4.93,0,0,1,.92-5.56c1.59-1.58,3.48-1.59,5.55-.93a12.727,12.727,0,0,1,6.78,5.62c12.6,19.53,18.98,40.73,16.97,64.12a6.221,6.221,0,0,1-.39,1.27c-1.03-.08-1.02-.83-1.18-1.41C42.75,98.34,33.57,91.37,20.87,88.21c-3.55-.88-7.2-1.51-10.35-3.45-1.85-1.14-3.71-2.63-2.45-5.23,1.16-2.4,3.23-2.67,5.51-1.78,5.1,1.99,10.18,4.04,15.29,6.03A18.4,18.4,0,0,0,31.53,84.16Z" fill="none" stroke="#000" stroke-width="2" class="otLUcWWY_2"></path>
        </g>
    </svg>
    <svg class="absStretch puschelBG" xmlns="http://www.w3.org/2000/svg" width="135.28" height="157.15" viewBox="0 0 135.28 157.15">
  <g id="puschel" transform="translate(-6.95 -7.883)">
    <path id="Path_1" data-name="Path 1" d="M69.04,76.52c9.63-8.39,18.27-17.48,27.88-25.58,8.02-6.76,16.22-13.28,25.81-17.78,3.94-1.85,7.96-3.43,12.45-2.98,6.16.61,8.36,5.12,4.83,10.17-3.15,4.49-7.9,7.08-12.57,9.61-14.8,8.02-29.28,16.5-41.96,27.69a30.8,30.8,0,0,0-8.55,11.09c6.2-3.17,11.27-5.79,16.37-8.38,8.89-4.52,17.84-8.93,27.09-12.69a20.619,20.619,0,0,1,9.24-1.69c3.98.26,7.44,1.55,8.26,5.8.75,3.86-1.93,6.19-5.15,7.61a63.7,63.7,0,0,1-7.99,2.72c-8.14,2.35-16.38,4.41-24.46,6.96C76.31,96.62,60.74,112.16,55.18,137c-2.09,9.32-4.2,18.64-6.56,27.91.7-37,3.91-73.56,19.62-107.81,6.02-13.13,14.14-24.79,25.58-33.91,4.52-3.6,9.4-6.28,15.47-5.88,1.92.13,3.76.5,4.69,2.29,1.06,2.04.13,3.94-1.26,5.47a33.634,33.634,0,0,1-4.11,3.6A150.71,150.71,0,0,0,72.58,69.61C71.25,71.82,70.21,74.21,69.04,76.52Z" stroke="#707070" stroke-width="1"/>
    <path id="Path_2" data-name="Path 2" d="M50.07,42.8c.62-8.62.75-16,2.18-23.25a26.684,26.684,0,0,1,2.88-8.42c.95-1.64,2.17-3.1,4.33-2.68a4.8,4.8,0,0,1,3.93,4c.77,3.35.06,6.58-.56,9.84-1,5.18-1.95,10.37-3.04,16.21,2.27-1.08,2.59-2.76,3.32-4.02,3.32-5.75,6.57-11.53,11.35-16.27,1.83-1.81,3.84-3.18,6.52-3.19,1.5-.01,3.13-.03,3.99,1.47a3.637,3.637,0,0,1-.57,4.17c-1.5,1.99-3.05,3.93-4.61,5.87A140.474,140.474,0,0,0,53.98,76.01c-.35,1.22-.84,2.4-1.75,4.94-1.56-5.78-2.74-10.31-4.01-14.81-3.21-11.38-7.21-22.35-15.2-31.51-2.28-2.61-5.22-6.55-1.5-9.54,3.85-3.09,7.04.34,9.56,3.37C44.44,32.5,46.67,37.29,50.07,42.8Z" stroke="#707070" stroke-width="1"/>
    <path id="Path_3" data-name="Path 3" d="M31.53,84.16c-4.64-6.62-10.88-9.88-17.18-13.07A48.124,48.124,0,0,1,9.5,68.6c-1.41-.94-2.48-2.35-1.85-4.23a4.409,4.409,0,0,1,4.02-3.2c3.78-.38,6.77,1.41,9.62,3.45,5.85,4.17,10.64,9.47,15.52,14.75A49.568,49.568,0,0,0,22.29,54.7a16.778,16.778,0,0,1-4.75-6.29,4.93,4.93,0,0,1,.92-5.56c1.59-1.58,3.48-1.59,5.55-.93a12.727,12.727,0,0,1,6.78,5.62c12.6,19.53,18.98,40.73,16.97,64.12a6.221,6.221,0,0,1-.39,1.27c-1.03-.08-1.02-.83-1.18-1.41C42.75,98.34,33.57,91.37,20.87,88.21c-3.55-.88-7.2-1.51-10.35-3.45-1.85-1.14-3.71-2.63-2.45-5.23,1.16-2.4,3.23-2.67,5.51-1.78,5.1,1.99,10.18,4.04,15.29,6.03A18.4,18.4,0,0,0,31.53,84.16Z" stroke="#707070" stroke-width="1"/>
  </g>
</svg>

    <!-- <img class="absStretch" src="../../assets/icon/puschel_outline_animated.svg" alt=""> -->
    <!-- <img class="absStretch puschelBG" src="../../assets/icon/puschelbg.svg" alt=""> -->

</div>
</template>

<style>
.puschelBG {
    z-index: 0;
    opacity: 0;
}

.otLUcWWY_0 {
    stroke-dasharray: 638 640;
    stroke-dashoffset: 639;
    animation: otLUcWWY_draw 900ms ease-in 0ms forwards;
}

.otLUcWWY_1 {
    stroke-dasharray: 272 274;
    stroke-dashoffset: 273;
    animation: otLUcWWY_draw 900ms ease-in 0ms forwards;
}

.otLUcWWY_2 {
    stroke-dasharray: 279 281;
    stroke-dashoffset: 280;
    animation: otLUcWWY_draw 900ms ease-in 0ms forwards;
}

@keyframes otLUcWWY_draw {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes otLUcWWY_fade {
    0% {
        stroke-opacity: 1;
    }

    92.15686274509804% {
        stroke-opacity: 1;
    }

    100% {
        stroke-opacity: 0;
    }
}
</style>

<script>
export default {
    data() {
        return {}
    }
}
</script>
