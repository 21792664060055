<template>
<div v-if="!loading" class="ContainerView">
    <div class="row">
        <div class="w-full">
            <div class="secInd">
                Mediathek
            </div>
            <h1 class="greetingsCont text-6xl">
                {{subcontName}}
            </h1>
        </div>
        <div class="w-full my-4">
            <files-view :view="prevFileView()" :files="fspfiles.data" />
        </div>
        <div class="w-full text-">
            <div v-if="showMoreEnabled" @click="showMore" class="btnPrime">
                Mehr laden
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { listSubContainer } from '@/graphql/queries'
import filesView from '../components/filesystem/filesView.vue'
export default {
    components: { filesView },
    props: {
        fileId: {
            type: String,
        }
    },
    data() {
        return {
            loading: true,
            files: 0,
            subcontName: null,
            page: 0,
            showMoreEnabled: false,

        }
    },
    apollo: {
        fspfiles: {
            query: listSubContainer,
            variables() {
                return {
                    "contId": {
                        "in": this.fileId
                    },
                    "page": this.page
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                this.files = res.data.fspfiles.data;
                this.loading = false;
                console.log(res);
                res.data.fspfiles.data[0].attributes.sub_containers.data.forEach(sc => {
                    if (sc.id == this.fileId) {
                        this.subcontName = sc.attributes.Name;
                    }
                });

            }
        },
    },
    methods: {
        prevFileView() {
            return this.$store.state.user.user.prevFileView;
        },
        showMore() {
            this.page++
            // Fetch more data and transform the original result
            this.$apollo.queries.fspfiles.fetchMore({
                // New variables
                variables() {
                    return {
                        "contId": {
                            "in": this.fileId
                        },
                        "page": this.page
                    }
                },
                // Transform the previous result with new data
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newFiles = fetchMoreResult.fspfiles.data;
                    // const hasMore = fetchMoreResult.fspfiles.hasMore

                    this.showMoreEnabled = true;

                    return {
                        fspfiles: {
                            data: newFiles.concat(previousResult.fspfiles.data)
                            // [...previousResult.fspfiles.data, ...newFiles]
                        }
                        // __typename: previousResult.tagsPage.__typename,
                        // Merging the tag list
                        // tags: [...previousResult.tagsPage.tags, ...newTags],
                        // hasMore,
                        // },
                    }
                },
            })
        },
    },
}
</script>
