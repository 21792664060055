<template>
<div class="topNavWrapper row mb-10 fyCenter">
    <div class="col-4">
        <router-link :to="{ name: 'Dashboard'}">
            <img class="w-60 dashBoardLogo" src="@/assets/imgs/fspLogo.svg" alt="">
        </router-link>
    </div>
    <div class="col-8 fyCenter fxEnd">
        <fsp-loader />
        <div v-if="loggedInUser.canUpload === true" class="uploadFile hidden xl:block mr-12">
            <router-link :to="{ name: 'Notify'}" class="btnOutline mr-4 smaller">
                Notify
            </router-link>
            <router-link :to="{ name: 'Upload'}" class="btnOutline smaller">
                Datei Upload
            </router-link>
        </div>
        <div class="userConfigCont fyCenter">
            <get-avatar class="mr-1 md:mr-4" :url="loggedInUser.Avatarurl" type="small" form="round" />
            <div class="quickNavUser">
                <div class="userConfigname hidden xl:block  uppercase">
                    {{loggedInUser.Firstname}} {{loggedInUser.Lastname}}
                </div>
                <div class="chevDown ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.395" height="8.622" viewBox="0 0 10.395 8.622">
                        <g id="Group_2129" data-name="Group 2129" transform="translate(10.395) rotate(90)">
                            <path id="Path_41" data-name="Path 41" d="M0,0,5.2,5.2,0,10.395l3.427,0L8.622,5.2,3.462,0Z" fill="currentColor" />
                        </g>
                    </svg>
                </div>
                <div class="quickLinksUser">
                    <div class="quickNavUserWrapper">
                        <div v-if="loggedInUser.canUpload === true">
                            <router-link :to="{ name: 'Upload'}" class="">
                                Datei Upload
                            </router-link>
                        </div>
                        <router-link to="/profile" class="">
                            Profil
                        </router-link>
                        <a href="/" @click.prevent="userLogout" class="logOut w-full block">
                            Ausloggen
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div @click="openMobileMenu" class="mobileBurger ml-2 md:ml-4 block rounded-full bgCamp cursor-pointer fCenter navBubbleDimension xl:hidden">
            <svg fill="var(--fWhite)" class="h-4 md:h-6 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" /></svg>
        </div>
    </div>
</div>
</template>

<script>
import getAvatar from "../user/getAvatar.vue";
import fspLoader from "../meta/fspLoader.vue";

export default {
    components: { getAvatar, fspLoader },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        }
    },
    methods: {
        userLogout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({
                    path: '/'
                })
            })

        },
        openMobileMenu() {
            let state = true;
            if (this.$store.state.mobileMenu === true) {
                state = false;
            } else {
                state = true;
            }
            this.$store.dispatch('openMobileMenu', state);
        }
    },
}
</script>
