<script>
// import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import deLocale from '@fullcalendar/core/locales/de';
import listPlugin from '@fullcalendar/list';
import {
    getFilesByDeadline,
} from '@/graphql/queries';
import formatDate from '@/composables/formats';

export default {
    props: {
        view: {
            type: String,
            default: 'dayGridWeek'
        }
    },
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    apollo: {
        fspfiles: {
            query: getFilesByDeadline,
            variables: {
                min: formatDate(new Date(), 'iso'),
                max: formatDate(new Date().setFullYear(new Date().getFullYear() + 1), 'iso')
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                var evs = [];
                res.data.fspfiles.data.forEach(e => {
                    let ev = {
                        title: e.attributes.Name,
                        start: e.attributes.Deadline,
                        url: '/files/showFileById/' + e.id,
                        eventContent: e.attributes.departments.data[0].attributes.Name
                    }
                    evs.push(ev);
                });
                this.calendarOptions.events = evs;
                this.loading = false;
            }
        },
    },
    data() {
        return {
            loading: true,
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                initialView: this.view,
                locale: deLocale,
                height: 650,
                events: [],
                eventDisplay: 'block',
                eventBackgroundColor: 'var(--cCurrent)',
                eventBorderColor: 'var(--cCurrent)',
                eventClick: function (info) {
                    this.$router.push(info.event.url)
                }
            },
            maxDate: new Date()
        }
    },
    methods: {
        dateWithMonthsDelay(months) {
            const date = new Date()
            date.setMonth(date.getMonth() + months)
            return date
        }
    }
}
</script>
<template>
<FullCalendar v-if="!loading"  :options="calendarOptions" />
</template>
