<template>
<div class="dashWrapper w-full">
    <div v-if="user" class="row">
        <div class="col-md-6">
            <div class="secInd">
                Dashboard
            </div>
            <h1 class="greetingsCont text-4xl xl:text-8xl">
                Hallo, {{user.Firstname}}!
            </h1>
        </div>
        <div class="col-md-6 mdHidden fxCenter">
            <user-card :user="user" />
        </div>
        <div class="col-md-6 mt-6">
            <div class="medHeader">
                <h3>Kalender</h3>
                <router-link :to="{ name: 'Kalender' }" class="linkPrime">
                 <span class="hidden md:inline-block">  Zum Kalender </span> 
                </router-link>

            </div>
            <div class="mt-4 calendarColBody">
                <calendar-view view="dayGridDay" />
            </div>
        </div>
        <div class="col-md-6 mt-6">
            <div class="medHeader">
                <h3>Letzte Uploads</h3>
                <router-link :to="{ name: 'Suche' }" class="linkPrime">
                    <span class="hidden md:inline-block">    Nach Datei suchen </span>
                </router-link>
            </div>
            <div class="mt-4 listColBody">
                <list-recent />
            </div>
        </div>
    </div>
    <bottom-nav class="mdHidden" />
</div>
</template>

<script>
import BottomNav from '../components/framework/bottomNav.vue';
import userCard from '../components/user/userCard.vue';
import ListRecent from "../components/filesystem/listRecent.vue";
import CalendarView from './CalendarView.vue';
export default {
    name: "Dashboard",
    components: {
        userCard,
        BottomNav,
        ListRecent,
        CalendarView
    },
    data() {
        return {

        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        }
    },
    mounted() {}
}
</script>
