<template>
<div v-if="!loading">
    <div v-if="form == 'list'" class="containerList">
        <div class="uploadChooseWrap">
            <div @click="toggleActive(c.id)" v-for="c in containers.data" :key="c.id" :class="{'active' : isActive(c.id)}" class="btnChoose">
                {{c.attributes.Name}}
            </div>
        </div>
    </div>
    <div v-if="form == 'select'" class="containerList">
        <treeselect :flat="true" :default-expand-level="1" placeholder="Bitte Ablageort wählen" v-model="$store.state.cContainers" :multiple="false" :options="conts" />
    </div>
</div>
</template>

<script>
import {
    getContainer,
} from '@/graphql/queries';
import Treeselect from '@bosquig/vue3-treeselect'
import '@bosquig/vue3-treeselect/dist/vue3-treeselect.css'
export default {
    props: {
        form: {
            type: String,
            default: 'list'
        }
    },
    components: {
        Treeselect
    },
    watch: {
        cContainers(newCont, oldCont) {
            this.toggleActive(newCont);
        },
    },
    apollo: {
        $loadingKey: 'loading',
        containers: {
            query: getContainer,
            prefetch: true,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            result(res) {
                res.data.containers.data.forEach(e => {
                    if (e.attributes.sub_containers.data.length) {
                        let subs = [];
                        e.attributes.sub_containers.data.forEach(sub => {

                            let s = {
                                "id": "s_" + sub.id,
                                "label": sub.attributes.Name,
                                "isDisabled": false
                            }
                            subs.push(s);
                        });
                        let c = {
                            "id": e.id,
                            "label": e.attributes.Name,
                            "isDisabled": e.attributes.isDisabled,
                            "children": subs
                        }
                        this.conts.push(c);
                    } else {
                        let c = {
                            "id": e.id,
                            "label": e.attributes.Name,
                            "isDisabled": e.attributes.isDisabled,
                        }

                        this.conts.push(c);
                    }
                });

            }
        },
    },

    data() {
        return {
            cContainers: 1,
            conts: [],
            loading: 0
        }
    },
    mounted() {
        this.$emit('update', this.cContainers);
    },
    methods: {
        toggleActive(id) {
            this.$store.dispatch('updateToggleContainer', id);
        },
        isActive(id) {
            if (this.$store.state.cContainers == id) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>
