import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Login from '@/views/LoginPage.vue'
import Dash from '@/views/DashPage.vue'
import Profile from '@/views/ProfileView.vue';
import SingleFile from '@/views/files/showFilesByID.vue';
import EditFile from '@/views/files/editFilesByID.vue';
import UserById from '@/views/user/userById.vue';
import UploadFile from '@/views/UploadView.vue';
import UploadFileV2 from '@/views/UploadViewV2.vue';
import CalendarView from '@/views/CalendarView.vue';
import SubContainerView from '@/views/SubContainerView.vue';
import ContainerView from '@/views/ContainerView.vue';
import SearchView from '@/views/SearchView.vue';
import NotifyView from '@/views/NotifyView.vue';
import UserlistViewVue from '@/views/UserlistView.vue';
import GroupById from '@/views/files/showGroupByID.vue';
import UploadTest from  '@/views/UploadTest.vue';
import Store from '@/store/index'

function requireAuth(to, from, next) {
  var isAuthenticated = false;
  if (sessionStorage.getItem('user'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    Store.dispatch('openMobileMenu', false);
    next(); // allow to enter route
  } else {
    next('/'); // go to '/login';
  }
}



function isLogged(to, from, next) {
  var isAuthenticated = false;
  if (sessionStorage.getItem('user'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next('/dash'); // allow to enter route
  } else {
    next(); // go to '/login';
  }
}

const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: isLogged
  },
  {
    path: '/dash',
    name: 'Dashboard',
    component: Dash,
    beforeEnter: requireAuth
  },
  {
    path: '/profile',
    name: 'Profil',
    component: Profile,
    beforeEnter: requireAuth
  },
  {
    path: '/files/showfilebyid/:fileId',
    name: 'Datei',
    props: true,
    component: SingleFile,
    beforeEnter: requireAuth
  },
  {
    path: '/files/editfilebyid/:fileId',
    name: 'Datei bearbeiten',
    props: true,
    component: EditFile,
    beforeEnter: requireAuth
  },
  {
    path: '/files/upload/',
    name: 'UploadV1',
    props: true,
    component: UploadFile,
    beforeEnter: requireAuth
  },
  {
    path: '/files/uploadv2/',
    name: 'Upload',
    props: true,
    component: UploadFileV2,
    beforeEnter: requireAuth
  },
  {
    path: '/calendar/',
    name: 'Kalender',
    props: true,
    component: CalendarView,
    beforeEnter: requireAuth
  },
  {
    path: '/container/:fileId',
    name: 'Mediathek',
    props: true,
    component: ContainerView,
    beforeEnter: requireAuth
  },
  {
    path: '/subcontainer/:fileId',
    name: 'Kampagne',
    props: true,
    component: SubContainerView,
    beforeEnter: requireAuth
  },
  {
    path: '/search/',
    name: 'Suche',
    props: route => ({ stitle: route.query.stitle }),
    component: SearchView,
    beforeEnter: requireAuth
  },
  {
    path: '/userlist',
    name: 'User',
    component: UserlistViewVue,
    beforeEnter: requireAuth
  },
  {
    path: '/notify',
    name: 'Notify',
    component: NotifyView,
    beforeEnter: requireAuth
  },
  {
    path: '/uploadaws',
    name: 'Upload (AWS)',
    component: UploadTest,
    beforeEnter: requireAuth
  },
  {
    path: '/user/userById/:userId',
    name: 'UserDetail',
    props: true,
    component: UserById,
    beforeEnter: requireAuth
  },
  {
    path: '/group/groupById/:groupId',
    name: 'Gruppe',
    props: true,
    component: GroupById,
    beforeEnter: requireAuth
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

export default router