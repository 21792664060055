<template>
<div class="LeftNav contentP" :class="{'mobileActive' : $store.state.mobileMenu}">
    <div class="upperLeftNav">
        <div class="userMenuWrapper mb-12">
            <form class="quickSearch" @submit.prevent="pushSearch" action="">
                <input type="text" class="searchInput" v-model="sTitle" placeholder="SUCHEN">
                <div @click="pushSearch" class="submitBtn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.851" height="13.853" viewBox="0 0 13.851 13.853">
                        <path id="magnifying-glass-solid" d="M13.536,12,10.3,8.766A5.627,5.627,0,1,0,8.767,10.3l3.238,3.238A1.083,1.083,0,0,0,13.536,12ZM2.141,5.627A3.463,3.463,0,1,1,5.6,9.09,3.467,3.467,0,0,1,2.141,5.627Z" transform="translate(-0.001 0)" fill="currentColor" />
                    </svg>

                </div>
            </form>
            <router-link :to="{  path: '/search?q=hi' }" class="mt-2 block mb-8">
                Erweiterte Suche
            </router-link>
            <div class="leftNavLabel">Mein Profil</div>
            <router-link class="leftNavLink" :to="{ name: 'Dashboard' }">
                <img src="@/assets/icon/dashboard.svg" alt="">
                Dashboard
            </router-link>
            <router-link class="leftNavLink" :to="{ name: 'Profil' }">
                <img src="@/assets/icon/profil.svg" alt="">
                Profil
            </router-link>
            <router-link class="leftNavLink" :to="{ name: 'User' }">
                <img src="@/assets/icon/usergroup.svg" alt="">
                User
            </router-link>

            <router-link class="leftNavLink" :to="{ name: 'Kalender' }">
                <img src="@/assets/icon/calendar.svg" alt="">
                Mein Kalender
            </router-link>
        </div>

        <div v-if="!loading" class="mediaMenuWrapper">
            <div class="leftNavLabel">Mediathek</div>

            <div v-for="c in sortConts" :key="c.id" class="containerItem">
                <div class="containerHasSub" v-if="c.attributes.sub_containers.data.length" alt="">
                    <div @click="toggleSubCont(c.id)" class="leftNavLink">
                        <img src="@/assets/icon/folder.svg" alt="">
                        {{c.attributes.Name}}
                        <img class="chevUpDown" :class="{'active' : opContainer.includes(c.id)}" src="@/assets/icon/chevUp.svg">
                    </div>
                    <div class="subContainerWraper" :class="{'active' : opContainer.includes(c.id)}">
                        <div v-for="s in c.attributes.sub_containers.data" :key="s.id" class="subContainerLink">
                            <router-link class="leftNavLink" :to="'/subcontainer/'+s.id">
                                {{s.attributes.Name}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <router-link class="leftNavLink" :to="'/container/'+c.id" v-else>
                    <img src="@/assets/icon/folder.svg" alt="">
                    {{c.attributes.Name}}
                </router-link>
            </div>

            <div class="leftNavLabel mt-8">Support</div>
            <a href="mailto:support@brotsalz.de?subject=Campaign Tool Feedback" class="leftNavLink">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 mr-2 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                Feedback
            </a>

        </div>
    </div>
    <div class="lowerLeftNav">
        <div class="PageIndicatorWrapper">
            <div class="leftNavIndicator">
                <div class="leftNavPalastInd">
                    PALAST
                </div>
                <div class="leftNavCurrentInd">
                    {{$route.name}}
                </div>
            </div>
        </div>

        <div @click.prevent="userLogout" class="leftNavLogOut">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.802" height="14.262" viewBox="0 0 13.802 14.262">
                <path id="power-off-solid" d="M23.821,7.361a.92.92,0,1,1-1.84,0V.92a.92.92,0,1,1,1.84,0Zm-5.981,0a5.061,5.061,0,1,0,8.3-3.893.92.92,0,1,1,1.176-1.415,6.9,6.9,0,1,1-8.822,0,.921.921,0,0,1,1.3.119.944.944,0,0,1-.121,1.3A5.054,5.054,0,0,0,17.84,7.361Z" transform="translate(-16)" fill="#f97267" />
            </svg>
            Ausloggen

        </div>
    </div>

</div>
</template>

<script>
import {
    getContainer
} from '@/graphql/queries';

export default {
    data() {
        return {
            opContainer: [],
            loading: true,
            sTitle: ''
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler(newValue, oldValue) {
                this.$store.dispatch('openMobileMenu', false);
            }
        }
    },
    computed: {
        sortConts() {
            let sConts = structuredClone(this.containers.data);
            sConts.forEach(c => {
                c.attributes.sub_containers.data.sort(function (a, b) {
                    return a.attributes.order - b.attributes.order;
                });
            });
            sConts.sort(function (a, b) {
                return a.attributes.order - b.attributes.order;
            });
            return sConts;
        }
    },
    apollo: {
        containers: {
            query: getContainer,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            result(res) {
                this.loading = false;
            }
        },
    },
    methods: {
        toggleSubCont(id) {
            if (!this.opContainer.includes(id)) {
                this.opContainer.push(id);
            } else {
                this.opContainer.splice(this.opContainer.indexOf(id), 1); //deleting
            }
        },
        userLogout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({
                    name: 'Login'
                })
            })

        },
        pushSearch() {
            this.$router.push({ path: '/search', query: { stitle: this.sTitle }, hash: '#results' })

        }
    }
}
</script>
