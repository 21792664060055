const getTheFile = (file) => {
    let tFileTemp = {
        "url": "",
        "name": "",
        "size": 0,
        "ext": "",
        "prevUrl": "", 
        "version": "v2"
    }

    if (file.data.attributes.fspFileLink != '' && file.data.attributes.fspFileLink != null) {
        tFileTemp.url = file.data.attributes.fspFileLink;
        tFileTemp.version = "v2";
        tFileTemp.ext = file.data.attributes.fspFileLink.split(/[#?]/)[0].split('.').pop().trim();
        tFileTemp.size = "Unbekannt";
    } else if (file.data.attributes.fspFileV2 != '' && file.data.attributes.fspFileV2 != null) {
        tFileTemp.url = file.data.attributes.fspFileV2.url;
        tFileTemp.name = file.data.attributes.fspFileV2.name;
        tFileTemp.size = file.data.attributes.fspFileV2.size;
        tFileTemp.version = "v2";
        tFileTemp.ext = file.data.attributes.fspFileV2.name.substring(file.data.attributes.fspFileV2.name.lastIndexOf("."));
    } else {
        tFileTemp.url = file.data.attributes.fspFile.data.attributes.url;
        tFileTemp.name = file.data.attributes.fspFile.data.attributes.name;
        tFileTemp.size = file.data.attributes.fspFile.data.attributes.size;
        tFileTemp.ext = file.data.attributes.fspFile.data.attributes.ext;
        tFileTemp.version = "v2";
    }

    if(file.data.attributes.PrevLink != '') {
        tFileTemp.prevUrl = file.data.attributes.PrevLink;
    }

    return tFileTemp;

}

export default getTheFile;