<template>
<div class="prevImgWrapper">
    <div class="prevImgData prevImageDateImg" v-if="isFile() == 'prevUrl'">
        <img class="prevImgImg" :src="file.prevUrl" alt="">
    </div>
    <div class="prevImgData prevImageDateImg" v-if="isFile() == 'hasPrev'">
        <img class="prevImgImg" :src="file.Vorschaubild.data.attributes.url" alt="">
    </div>
    <div class="prevImgData prevImageDateImg" v-if="isFile() == 'svg'">
        <img class="prevImgImg" :src="file.url" alt="">
    </div>
    <div class="prevImgData" v-if="isFile() == 'pdf'">
        <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
            <g id="Group_2481" data-name="Group 2481" transform="translate(-928.5 -6727.5)">
                <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.721,7.721,0,0,0-2.261-5.458L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.5 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_13180" fill="currentColor" data-name="Path 13180" d="M2.88-24.012V0H6.552V-9.756H8.208a8.668,8.668,0,0,0,5.868-1.872,6.725,6.725,0,0,0,2.34-5.256,6.95,6.95,0,0,0-2.988-5.832,9.384,9.384,0,0,0-5.256-1.3Zm3.672,3.384h1.62c4.176,0,4.716,2.376,4.716,3.708,0,2.232-1.512,3.78-4.68,3.78H6.552ZM20.16-24.012V0h4.968c3.348,0,5.976-.324,8.784-2.3a11.45,11.45,0,0,0,4.824-9.684,11.886,11.886,0,0,0-4.608-9.684c-2.664-1.98-5.58-2.34-8.928-2.34Zm3.672,3.456h1.584A10.757,10.757,0,0,1,31.824-18.9a8.556,8.556,0,0,1,3.24,6.912,8.466,8.466,0,0,1-3.276,6.84,10.4,10.4,0,0,1-6.372,1.692H23.832Zm31.536-3.456H43.344V0h3.672V-11.016H55.08v-3.456H47.016v-6.084h8.352Z" transform="translate(956 6811)" />
            </g>
        </svg>
    </div>
    <div class="prevImgData" v-if="isFile() == 'doc'">
        <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
            <g id="Group_2481" data-name="Group 2481" transform="translate(-928.055 -6727.5)">
                <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.055 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_13181" fill="currentColor" data-name="Path 13181" d="M2.88-24.012V0H7.848c3.348,0,5.976-.324,8.784-2.3a11.45,11.45,0,0,0,4.824-9.684,11.886,11.886,0,0,0-4.608-9.684c-2.664-1.98-5.58-2.34-8.928-2.34Zm3.672,3.456H8.136A10.757,10.757,0,0,1,14.544-18.9a8.556,8.556,0,0,1,3.24,6.912,8.466,8.466,0,0,1-3.276,6.84A10.4,10.4,0,0,1,8.136-3.456H6.552ZM37.4-24.444A12.181,12.181,0,0,0,24.912-11.988,12.181,12.181,0,0,0,37.4.468,12.181,12.181,0,0,0,49.9-11.988,12.181,12.181,0,0,0,37.4-24.444Zm0,21.528c-5.148,0-8.82-3.744-8.82-9.072s3.672-9.072,8.82-9.072,8.82,3.744,8.82,9.072S42.552-2.916,37.4-2.916Zm34.884-19.8a11.411,11.411,0,0,0-6.408-1.728A11.891,11.891,0,0,0,57.2-21.312a12.564,12.564,0,0,0-3.852,9.4A11.773,11.773,0,0,0,57.2-2.844,12.029,12.029,0,0,0,65.7.468a13.1,13.1,0,0,0,6.588-1.8V-5.688a9.529,9.529,0,0,1-6.48,2.772,8.669,8.669,0,0,1-8.784-9c0-5.292,3.528-9.144,8.748-9.144A9.362,9.362,0,0,1,72.288-18.4Z" transform="translate(946.676 6811.444)" />
            </g>
        </svg>
    </div>
    <div class="prevImgData" v-if="isFile() == 'zip'">
        <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
            <g id="Group_2484" data-name="Group 2484" transform="translate(-2426 -6195)">
                <g id="Group_2481" data-name="Group 2481" transform="translate(1497.945 -532.5)">
                    <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.055 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                </g>
                <path id="Path_13185" data-name="Path 13185" d="M18.864-24.012H3.024v3.456h9.864L1.08,0h17.5V-3.456H7.056Zm3.96,0V0H26.5V-24.012Zm9.432,0V0h3.672V-9.756h1.656a8.668,8.668,0,0,0,5.868-1.872,6.725,6.725,0,0,0,2.34-5.256A6.95,6.95,0,0,0,42.8-22.716a9.384,9.384,0,0,0-5.256-1.3Zm3.672,3.384h1.62c4.176,0,4.716,2.376,4.716,3.708,0,2.232-1.512,3.78-4.68,3.78H35.928Z" transform="translate(2458 6278)" fill="currentColor" />
            </g>
        </svg>
    </div>
    <div class="prevImgData" v-if="isFile() == 'eps'">
        <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
            <g id="Group_2481" data-name="Group 2481" transform="translate(-927.666 -6727.5)">
                <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(924.666 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_13188" fill="currentColor" data-name="Path 13188" d="M16.128-24.012H2.88V0H16.128V-3.456H6.552v-7.56H15.84v-3.456H6.552v-6.084h9.576Zm4.752,0V0h3.672V-9.756h1.656a8.668,8.668,0,0,0,5.868-1.872,6.725,6.725,0,0,0,2.34-5.256,6.95,6.95,0,0,0-2.988-5.832,9.384,9.384,0,0,0-5.256-1.3Zm3.672,3.384h1.62c4.176,0,4.716,2.376,4.716,3.708,0,2.232-1.512,3.78-4.68,3.78H24.552Zm26.928.216a8.179,8.179,0,0,0-1.836-2.412,7.081,7.081,0,0,0-4.824-1.62c-4.5,0-7.02,3.1-7.02,6.336,0,3.6,2.916,5.256,4.9,6.084l2.3.972c1.584.648,3.456,1.656,3.456,4.1A3.858,3.858,0,0,1,44.5-2.916a3.789,3.789,0,0,1-3.132-1.44,5.185,5.185,0,0,1-1.008-3.06l-3.636.792a7.774,7.774,0,0,0,2.016,4.68A7.025,7.025,0,0,0,44.388.468a7.4,7.4,0,0,0,7.74-7.7c0-2.736-1.224-5.256-5.652-7.056l-2.412-.972c-2.34-.936-2.592-2.2-2.592-2.916,0-1.548,1.152-2.88,3.384-2.88a3.829,3.829,0,0,1,2.592.864,3.814,3.814,0,0,1,1.08,1.512Z" transform="translate(956.286 6811.444)" />
            </g>
        </svg>
    </div>
    <div class="prevImgData" v-if="isFile() == 'tiff'">
        <svg xmlns="http://www.w3.org/2000/svg" width="144.657" height="143" viewBox="0 0 144.657 143">
            <g id="Group_2482" data-name="Group 2482" transform="translate(-2835.172 -6144.5)">
                <path id="Path_13182" data-name="Path 13182" d="M4,108.25,43.841,68.409a17.375,17.375,0,0,1,24.568,0L108.25,108.25M90.875,90.875,104.653,77.1a17.375,17.375,0,0,1,24.568,0L143,90.875M90.875,38.75h.087M21.375,143h104.25A17.375,17.375,0,0,0,143,125.625V21.375A17.375,17.375,0,0,0,125.625,4H21.375A17.375,17.375,0,0,0,4,21.375v104.25A17.375,17.375,0,0,0,21.375,143Z" transform="translate(2834 6142.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_13184" fill="currentColor" data-name="Path 13184" d="M15.048-20.556v-3.456H.36v3.456H5.868V0H9.54V-20.556Zm3.24-3.456V0H21.96V-24.012Zm21.456,0H27.72V0h3.672V-11.016h8.064v-3.456H31.392v-6.084h8.352Zm16.2,0H43.92V0h3.672V-11.016h8.064v-3.456H47.592v-6.084h8.352Z" transform="translate(2851 6189)" />
            </g>
        </svg>
    </div>
    <div class="prevImgData" v-if="isFile() == 'movie'">
        <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
            <g id="Group_2485" data-name="Group 2485" transform="translate(-2675 -6364)">
                <g id="Group_2481" data-name="Group 2481" transform="translate(1746.945 -363.5)">
                    <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.055 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                </g>
                <path id="Path_13187" data-name="Path 13187" d="M55.2,7.5V37.947M37.8,7.5V37.947M3,34.5V10.951A3.262,3.262,0,0,1,7.88,8.118L28.492,19.893a3.262,3.262,0,0,1,0,5.666L7.88,37.335A3.262,3.262,0,0,1,3,34.5Z" transform="translate(2701.5 6416)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
        </svg>
    </div>
    <div class="prevImgData prevImageDateImg" v-if="isFile() == 'img'">
        <img class="prevImgImg" v-if="file.PrevLink" :src="file.PrevLink" alt="">
        <img class="prevImgImg" v-else-if="file.version == 'v2'" :src="file.url" alt="">
        <img class="prevImgImg" v-else :src="file.url " alt="">
    </div>
    <div class="prevImgData" v-if="isFile() == 'bigImg'">
        <svg xmlns="http://www.w3.org/2000/svg" width="144.657" height="143" viewBox="0 0 144.657 143">
            <g id="Group_2482" data-name="Group 2482" transform="translate(-2835.172 -6144.5)">
                <path id="Path_13182" data-name="Path 13182" d="M4,108.25,43.841,68.409a17.375,17.375,0,0,1,24.568,0L108.25,108.25M90.875,90.875,104.653,77.1a17.375,17.375,0,0,1,24.568,0L143,90.875M90.875,38.75h.087M21.375,143h104.25A17.375,17.375,0,0,0,143,125.625V21.375A17.375,17.375,0,0,0,125.625,4H21.375A17.375,17.375,0,0,0,4,21.375v104.25A17.375,17.375,0,0,0,21.375,143Z" transform="translate(2834 6142.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_1" data-name="Path 1" d="M6.327,8.629V32H2.8V8.629ZM11.125,32,16.045,6.873l8.022,18.164,8.31-18.164L36.8,32H33.18L30.924,17.893,24.007,33.075l-6.721-15.2L14.773,32Zm42.64-12.17h9.657V20.6a15.951,15.951,0,0,1-.621,4.662,9.909,9.909,0,0,1-2.043,3.512,10.587,10.587,0,0,1-8.28,3.663,11.382,11.382,0,0,1-8.4-3.542,11.745,11.745,0,0,1-3.5-8.537A12.145,12.145,0,0,1,52.8,8.175a11.848,11.848,0,0,1,5.116,1.12,14.756,14.756,0,0,1,4.465,3.633l-2.513,2.407a8.527,8.527,0,0,0-7.008-3.83,8.373,8.373,0,0,0-6.221,2.558,8.541,8.541,0,0,0-2.513,6.3A8.781,8.781,0,0,0,52.6,29.139a7.047,7.047,0,0,0,4.677-1.756,6.247,6.247,0,0,0,2.316-4.238H53.766Z" stroke="currentColor" transform="translate(2848.172 6157)" />
            </g>
        </svg>
    </div>

</div>
</template>

<script>
import isFileType from '@/composables/formatType';

export default {
    props: {
        file: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'preview'
        }
    },
    data() {
        return {}
    },
    methods: {
        isFile() {
            console.log(this.file);
            if (this.file.prevUrl != '' && this.file.prevUrl != null) {
                return 'prevUrl';
            } else if (this.file.version == "v2") {
                return isFileType(this.file.ext, this.file.size);
            } else if (!this.file.Vorschaubild || this.file.Vorschaubild.data === null || this.type != 'preview') {
                return isFileType(this.file.fspFile.data.attributes.ext);
            } else {
                return 'hasPrev';
            }
        }
    }
}
</script>
