<template>
<div class="showFileWrapper">
    <div v-if="!loading">
        <show-file :canComment="true" :file="file" />
    </div>
</div>
</template>

<script>
import {
    getFspFile
} from '@/graphql/queries'
import showFile from '@/components/filesystem/showFile.vue'
import { getObjectByKey } from '@/composables/aws.js'

export default {
    props: {
        fileId: {
            type: String,
        }
    },
    data() {
        return {
            file: {},
            loading: true
        }
    },
    apollo: {
        fspfile: {
            query: getFspFile,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            variables() {
                return {
                    "id": parseInt(this.fileId)
                }
            },
            prefetch: true,
            result(res) {
                this.file = res.data.fspfile;
                console.log(this.file);
                this.loading = false;
            }
        },
    },

    components: {
        showFile
    },
    mounted() {
        console.log(this.$store.state.user.jwt);
    },
    methods: {
        async testID() {
            const result = await getObjectByKey(
                '1684312306002-2023-05-15-fil-keyvisual-cls-duck.png',
                '' // folder of the file, you should change it to your variable or a string
            )
            console.log(result);
        }
    }
}
</script>
