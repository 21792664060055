import gql from 'graphql-tag';




export const getContainer = gql `
query getContainers {
  containers(pagination: { limit: 999 }, sort: "id:asc" ) {
    data {
      id
      attributes {
        Name
        isDisabled
        order
        sub_containers {
          data {
            id
            attributes {
              Name
              order
            }
          }
        }
      }
    }
  }
}
`

export const getDepartmens = gql `
query getDepartmens {
    departments(pagination: {limit:999}) {
      data {
        id
        attributes {
          Name
       }
      }
    }
  }
  `

export const getDepartmentsUser = gql `
  query getDepartments {
    departments(pagination: {limit:999}) {
      data {
        id
        attributes {
          Name
          users {
            data {
              id
              attributes {username, email}
            }
          }
       }
      }
    }
  }
`

export const getChannels = gql `
query getChannels {
    channels(pagination: {limit:999}) {
      data {
        id
        attributes {
          Name
       }
      }
    }
  }
  `
export const getMediums = gql `
query getMediums {
    mediums(pagination: {limit:999}) {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
  `


export const getMaterials = gql `
  query getMaterials {
      materials(pagination: {limit:999}) {
        data {
          id
          attributes {
            Name
          }
        }
      }
    }
    `

export const getTags = gql `
query getTags {
  tags(pagination: {limit:999}) {
    data {
      id
      attributes {
        Name
      }
    }
  }
}
`

export const listRecent = gql `
query listRecent {
  fspfiles(sort: "updatedAt:desc", pagination: { limit: 8 }) {
    data {
      id
      attributes {
        Name
        fspFileLink
        fspFileV2
        updatedAt
        fspFile {
          data {
            attributes {
              size
            }
          }
        }
      }
    }
  }
}
`

export const getFspFile = gql `
query getFspFile($id: ID!) {
  fspfile(id: $id) {
    data {
      id
      attributes {
        Name
        Description
        DescType
        Deadline
        FileState
        PrevLink
        fspFileLink
        fspFileV2
        Comments
        containers {
          data {
            id
            attributes {
              Name
              sub_containers {
                data {
                  id
                  attributes {
                    Name
                  }
                }
              }
            }
          }
        }
        sub_containers {
          data {
            id
            attributes {
              Name
            }
          }
        }
        collections {
          data {
            id
            attributes {
              Name
            }
          }
        }
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
        channels {
          data {
            id
            attributes {
              Name
            }
          }
        }
        materials {
          data {
            id
            attributes {
              Name
            }
          }
        }
        mediums {
          data {
            id
            attributes {
              Name
            }
          }
        }
        tags {
          data {
            id
            attributes {
              Name
            }
          }
        }
        Vorschaubild {
          data {
            id
            attributes {
              url
            }
          }
        }
        fspFile {
          data {
            id
            attributes {
              size
              name
              url
              ext
              mime
              width
              height
            }
          }
        }
      }
    }
  }
}
`


export const getFilesBySubContainer = gql `
query getFilesBySubContainer($id: ID!) {
  subContainer(id: $id) {
    data {
      id
      attributes {
        Name
        fspfiles {
          data {
            id  
            attributes {
              Name
              PrevLink
              updatedAt
              fspFile {
                data {
                  attributes {
                    ext
                    size
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`

export const getFilesByContainer = gql `
query getFilesByContainer($id: ID!) {
  container(id: $id) {
    data {
      id
      attributes {
        Name
        fspfiles {
          data {
            id
            attributes {
              Name
              PrevLink
              updatedAt
              fspFile {
                data {
                  attributes {
                    size
                    url
                    ext
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`



export const getFilesByDeadline = gql `
query getFilesByDeadline($min: DateTime, $max: DateTime) {
  fspfiles(filters: { Deadline: { gt: $min, lt: $max } }) {
    data {
      id
      attributes {
        Name
        Deadline
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
      }
    }
  }
}

`
export const searchFspFile = gql `
query searchFspFile(
  $tags: [ID]
  $meds: [ID]
  $deps: [ID]
  $mats: [ID]
  $chans: [ID]
  $title: String
) {
  fspfiles(
    filters: {
      Name: { contains: $title }
      and: [
        { tags: { id: { in: $tags } } }
        { mediums: { id: { in: $meds } } }
        { departments: { id: { in: $deps } } }
        { materials: { id: { in: $mats } } }
        { channels: { id: { in: $chans } } }
      ]
    }
  ) {
    data {
      id
      attributes {
        Name
        Description
        DescType
        Deadline
        containers {
          data {
            id
            attributes {
              Name
              sub_containers {
                data {
                  id
                  attributes {
                    Name
                  }
                }
              }
            }
          }
        }
        sub_containers {
          data {
            id
            attributes {
              Name
            }
          }
        }
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
        channels {
          data {
            id
            attributes {
              Name
            }
          }
        }
        materials {
          data {
            id
            attributes {
              Name
            }
          }
        }
        mediums {
          data {
            id
            attributes {
              Name
            }
          }
        }
        tags {
          data {
            id
            attributes {
              Name
            }
          }
        }
        Vorschaubild {
          data {
            id
            attributes {
              url
            }
          }
        }
        fspFile {
          data {
            id
            attributes {
              size
              name
              url
              ext
              mime
            }
          }
        }
      }
    }
  }
}
`

export const getUsers = gql `
query getUsers {
  usersPermissionsUsers(pagination: {limit:999}) {
    data {
      id
      attributes {
        username
        email
        Position
        Rolename
        Firstname
        Lastname
        Avatarurl
        Tel
        
      }
    }
  }
}`

export const getUserByID = gql `
query getUserByID($id: ID) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        username
        email
        Position
        Rolename
        Firstname
        Lastname
        Tel
        Avatarurl
        departments {
          data {
            id
            attributes {
              Name
            }
          }
        }
      }
    }
  }
}`

export const getComments = gql `
query getComments($id: ID!) {
  fspfile(id: $id) {
    data {
      attributes {
        Comments
      }
    }
  } 
}`


export const getCollections = gql `
query getCollections {
  collections(pagination: {limit:999}) {
    data {
      id
      attributes {
        Name
      }
    }
  }
}`


export const getFspGroup = gql `
query getFspGroup($id: ID!) {
  collection(id: $id) {
    data {
      id
      attributes {
        Name
        fspfiles {
          data {
            id
            attributes {
              fspFileV2
              collections {
                data {id}
              }
              Name
              PrevLink
              updatedAt
              fspFile {
                data {
                  attributes {
                    ext
                    size
                    url
                  }
                }
              }
              Vorschaubild {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`


export const listSubContainer = gql `
query listContainer($contId:IDFilterInput, $page:Int) {
  fspfiles(filters: {sub_containers: {id:$contId}}, sort: "updatedAt:desc", pagination: { pageSize: 99, page:$page }) {
    data {
      id
      attributes {
        fspFileV2
        sub_containers {
          data {
            id
            attributes {Name}
          }
        }
        collections {
          data {
            id
            attributes {Name}
          }
        }
        Comments
        DescType
        Name
        PrevLink
        fspFileLink 
        updatedAt
        fspFile {
          data {
            attributes {
              size
              url
              ext
            }
          }
        }
        departments {
          data {
          id 
          attributes {Name}
        }
        }
        materials {
          data {
          id 
          attributes {Name}
        }
        }
        Vorschaubild {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}`


export const getFilesNameId = gql `
query getFilesNameId {
  fspfiles(pagination: { limit: 3000 }) {
    data {
      id
      attributes {
        Name
      }
    }
  }
}
`