<template>
<div class="avatarWrapper">
        <div class="userConfigAvatar" :class="[type, form]">
            <img v-if="url == '' || url == null"  src="@/assets/imgs/noAvatar.svg" >
            <img v-else  :src="url" alt=""/>
        </div>
</div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'small'
        },
        form: {
            type: String,
            default: 'rounded'
        },
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    }
}
</script>
