<template>
<div class="profileWrapper w-full">
    <div class="row mb-8 mw-md">
        <div class="w-full">
            <div class="secInd">
                Suchen
            </div>
            <h1 class="mt-6 mb-8 greetingsCont">
                Wonach suchst du?
            </h1>
        </div>
        <div class="col-md-6"></div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <input class="searchByName" placeholder="Name der Datei" v-model="searchTitle" type="text">
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <div class="uploadChooseContainer">
                <div class="label">Speicherort</div>
                <container-list form="select" />
            </div>
            <div class="uploadChooseContainer">
                <div class="label">Department</div>
                <department-list></department-list>
            </div>
            <div class="uploadChooseContainer">
                <div class="label">Kanal</div>
                <channel-list />
            </div>
        </div>
        <div class="col-md-6">
            <div class="uploadChooseContainer">
                <div class="label">Medium</div>
                <medium-list />
            </div>
            <div class="uploadChooseContainer">
                <div class="label">Material</div>
                <material-list />
            </div>
            <div class="uploadChooseContainer">
                <div class="label">Tags</div>
                <tag-list />
            </div>
        </div>
        <div class="w-full my-12">
            <div @click="searchData()" class="btnPrime">SUCHEN</div>
        </div>
        <div id="results" class="min-h-screen">
            <div v-if="loadingRes == 'completed'" class="w-full my-4">
                <div class="w-full" v-if="searchResults.length">
                    <files-view headerlabel="Deine Suchergbenisse" view="grid" :files="searchResults" />
                </div>
                <div class="w-full text-center my-12 text-2xl" v-else>
                    Es wurden keine Dateien gefunden
                </div>
            </div>
            <div v-if="loadingRes == 'loading'" class="w-full text-center my-12 text-2xl">
                Ergebnisse werden geladen ...
            </div>
            <div v-if="loadingRes == ''" class="w-full text-center my-12 text-2xl">
                Bitte gebe zunächst ein, wonach du suchst.
            </div>
        </div>

    </div>
</div>
</template>

<script>
import ContainerList from '../components/meta/containerList.vue';
import DepartmentList from '../components/meta/departmentList.vue';
import ChannelList from '../components/meta/channelList.vue';
import MediumList from '../components/meta/mediumList.vue';
import MaterialList from '../components/meta/materialList.vue';
import TagList from '../components/meta/tagList.vue';
import filesView from '../components/filesystem/filesView.vue'

import {
    searchFspFile,
} from '@/graphql/queries';

export default {
    props: {
        stitle: {
            type: String,
            default: ''
        }
    },
    components: {
        ContainerList,
        DepartmentList,
        ChannelList,
        MediumList,
        MaterialList,
        TagList,
        filesView
    },
    data() {
        return {
            searchResults: false,
            loadingRes: '',
            searchTitle: ''
        }
    },
    watch: {
        stitle: {
            handler(newVal, oldVal) {
                if (this.stitle != '' && newVal != oldVal) {
                    this.searchTitle = this.stitle;
                    this.searchData();
                } else {
                    this.searchResults = false;
                }
            },
            deep: true
        },
    },
    methods: {
        searchData() {
            this.loadingRes = 'loading';
            var vars = {};
            if (this.$store.state.cTags.length) {
                vars.tags = this.$store.state.cTags;
            }
            if (this.$store.state.cMeds.length) {
                vars.meds = this.$store.state.cMeds;
            }
            if (this.$store.state.cDepartments.length) {
                vars.deps = this.$store.state.cDepartments;
            }
            if (this.$store.state.cMa.length) {
                vars.mats = this.$store.state.cMa;
            }
            if (this.$store.state.cChans.length) {
                vars.chans = this.$store.state.cChans;
            }
            if (this.searchTitle != '') {
                vars.title = this.searchTitle;
            }

            this.$apollo.query({
                query: searchFspFile,
                variables: vars

            }).then((res) => {
                console.log(res);
                this.searchResults = res.data.fspfiles.data;
                this.loadingRes = 'completed';
            });
        },

    },
    created() {
        console.log(this.$route.query.stitle);
        if (this.stitle != '') {
            this.searchTitle = this.stitle;
            this.searchData();
        } else {
            this.searchResults = false;
        }
        this.$store.dispatch('clearUploadStates');
    },

}
</script>
