<template>
<div class="bottomNavWrapper fyCenter">
    <div class="row">
        <div class="col-md-6">
            <div class="fyCenter">
                <img src="@/assets/icon/envelope.svg" class="mr-2 h-4 w-4" alt="">
                Benötigst du Hilfe?
            </div>
        </div>
        <div class="col-md-6 fxEnd">
            <div class="themeSwitcher">
                <div class="w-10 text-center" :class="{ 'font-bold' : cTheme  == false }"> Dark</div>
                <input @change="toggleTheme($event)" v-model="cTheme" class="form-check-input appearance-none w-9 rounded-full mx-4 h-5 align-top bg-white bg-no-repeat bg-contain bgCamp bWhite border-2 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="themeSwitch">
                <div class="w-10 text-center" :class="{ 'font-bold' : cTheme  == true }"> Light</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    updateUser
} from "../../graphql/mutations";
export default {
    data() {
        return {
            cTheme: false
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.user.user;
        },

    },
    mounted() {

        this.cTheme = this.$store.state.cTheme

    },
    methods: {
        toggleTheme(event) {
            this.$store.dispatch('toggleTheme', this.cTheme)
            this.$apollo.mutate({
                mutation: updateUser,
                variables: {
                    "id": this.loggedInUser.id,
                    "data": {
                        "cTheme": this.cTheme
                    }
                }
            })
        }
    }
}
</script>
